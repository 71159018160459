import React from "react";
import "./NewsInfo.css"
import {Divider, Image} from "antd";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {htmlDecodeByRegExp} from "../../../../modules/HtmlUtil";
import {deploymentUrl, staticSourceUrl} from "../../../../config/setting";

class NewsInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            //新闻详情
            newInfo: {
                dataStatus: null,
                deleteFlag: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remarks: null,
                id: null,
                siteId: null,
                title: null,
                content: null,
                newsImage: null,
                newsType: null,
                newsLink: null,
                linkType: null
            },
            id: null
        }
    }

    componentDidMount() {
        // 请求单页数据并存储到state
        this.setState({id: this.props.match.params.param});
        this.queryNew(this.props.match.params.param);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.param !== this.state.id) {
            this.setState({ id: nextProps.match.params.param},() => {
                //当路由参数改变时，重新请求接口获取数据
                this.queryNew(nextProps.match.params.param);
            });
        }
    }

    render() {
        return (
            <div className={"newInfo-body"}>
                <div className={"newInfo-title-content"}>
                    <h1>
                        {this.state.newInfo.title}
                    </h1>
                    <h3>
                        {"发布时间：" + this.state.newInfo.createTime}
                    </h3>
                    <h3>
                        {"来源：云南省地图院"}
                    </h3>
                    <Divider/>
                    <div className={"ql-snow"}>
                        <div dangerouslySetInnerHTML={{__html: this.state.newInfo.content}} className="ql-editor"/>
                    </div>
                    <Image.PreviewGroup>
                        {this.state.newInfo.newsImage === null||undefined||"null" ? <div/> :
                            this.state.newInfo.newsImage.split(",").map((item, index) =>{
                                return(
                                    <div key={index}
                                         style={{display: "block", margin: "auto", width: 400}}>
                                        <Image src={staticSourceUrl + item}/>
                                    </div>
                                )
                            }
                        )}
                    </Image.PreviewGroup>
                </div>
            </div>
        );
    }

    // 查询新闻详情
    queryNew(id){
        fetch(deploymentUrl + '/tdtyns/TdtNewsNew/' + id,{
            method: 'GET'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询新闻公告失败");
                return;
            }
            // newInfo赋值
            let newData = null;
            for(let key in data.data){
                newData = Object.assign(this.state.newInfo,{[key]:data.data[key]});
            }
            newData.content = htmlDecodeByRegExp(newData.content);
            newData.createTime = newData.createTime.substring(0,10);
            this.setState({newInfo: newData});
        });
    }
}

export default NewsInfo
