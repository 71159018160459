import React from 'react';
import $ from 'jquery';
import './myAntdStyle.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';
import Header from './components/header/Header';

import Home from './components/body/homePage/Home';
import ServicesList from './components/body/services/ServicesList';
import ProduceList from './components/body/produce/ProduceList';
import Applications from './components/body/applications/Applications';
import Example from './components/body/example/Example';
import { getYNToken,
  getZWToken, removeYNToken,
  removeZWToken
} from './modules/CookieTools';

import DatumResource from './components/body/sources/DatumResource';
import MapResource from './components/body/sources/MapResource';
import SoftwareResource from './components/body/sources/SoftwareResource';
import YearPlan from './components/body/homePage/peopleWork/yearPlan/YearPlan';
import WaterDistribution from './components/body/homePage/peopleWork/waterDistribution/WaterDistribution';
import MineralDistribution from './components/body/homePage/peopleWork/mineralDistribution/MineralDistribution';

import './App.css';
import Support from './components/body/support/Support';
import SupportInfo from './components/body/support/SupportInfo';
import AdvertisingBoard from './components/advertisingBoard/AdvertisingBoard';
import defined from './modules/defined';
import { Store } from './store/Store';
import {
  removeName,
  removeUser,
  setName,
  setUser
} from './store/reducers/UserSore';
import {deploymentUrl, gatewayUrl, s_app_id, sAppId} from "./config/setting";

class App extends React.Component {
  constructor(props) {
    super(props);
    verifyTiandituLogin();
  }

  render() {
    return (
      <div className={'app-container'}>
        <Header />
        <div className={'app-homePage'}>
          <Switch>
            <Route path={'/index'} component={Home} />
            {/*支持 界面*/}
            <Route path={'/support'} component={Support} />
            <Route path={'/supportInfo'} component={SupportInfo} />

            {/*<Route path={"/user"} component={UserInfo}/>*/}
            {/*资源下载---App和标准地图*/}
            <Route path={'/MapResource'} component={MapResource} />
            <Route path={'/SoftwareResource'} component={SoftwareResource} />
            <Route path={'/DatumResource'} component={DatumResource} />
            {/*服务资源*/}
            <Route path={'/serviceList'} component={ServicesList} />
            {/*地图产品*/}
            <Route path={'/produce'} component={ProduceList} />
            {/*应用案例*/}
            <Route path={'/Applications'} component={Applications} />
            {/*API在线案例*/}
            <Route path={'/example'} component={Example} />
            {/*十四五规划*/}
            <Route path={'/yearPlan'} component={YearPlan} />
            {/*水资源分布*/}
            <Route path={'/waterDistribution'} component={WaterDistribution} />
            {/*矿产资源分布*/}
            <Route
              path={'/mineralDistribution'}
              component={MineralDistribution}
            />
            <Redirect exact form={'/'} to={'/index'} />
          </Switch>
        </div>
        <AdvertisingBoard />
      </div>
    );
  }
}


/**   校验国家天地图是否登录  */
function verifyTiandituLogin() {
  //获取国家用户信息---能获取到-国家用户登录；获取不到-未登录
  $.ajax({
    type: 'get',
    async: false,
    url: deploymentUrl + '/auth/tianditu/casInfo',
    success: function (res) {
      if (res.code === 200) {
        Store.dispatch(setUser(res.data));
        Store.dispatch(setName(res.data.loginEmail));
        removeZWToken()
        removeYNToken()
      } else {
        Store.dispatch(removeUser());
        Store.dispatch(removeName());
        loading();
      }
    },
    error: function (err) {
      Store.dispatch(removeUser());
      Store.dispatch(removeName());
      loading();
    }
  });
}


function loading() {
  //1、天地图--判断token是否存在--存在表示已经登录
 if (defined(getZWToken())) {
    //2、政务网--判断token是否存在--存在表示已经登录
    zwViewUserInfo();
  } else if(defined(getYNToken())) {
    //获取云南天地图云南用户信息
    getYNTdtUserInfo()
  }
}

//获取政务用户信息
function zwViewUserInfo() {
  let userName = Store.getState().user.userName;
  if (defined(userName)) {
    return;
  }
  let url =
    deploymentUrl +
    '/yntdtsite/ynzwsso/getUserInfo?token=' +
    getZWToken() +
    '&refer=NA';
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    async: false,
    success: function (data) {
      if (data == null || data === 'null') {
        removeZWToken();
        Store.dispatch(removeUser());
        Store.dispatch(removeName());
      } else {
        Store.dispatch(setUser(data.userInfo));
        Store.dispatch(setName(data.userInfo.userName));
      }
    },
    error: function (err) {
      removeZWToken();
      Store.dispatch(removeUser());
      Store.dispatch(removeName());
    }
  });
}

//天地图云南用户信息
async function getYNTdtUserInfo() {
  let userName = Store.getState().user.userName;
  if (defined(userName)) {
    return;
  }
  let url =  gatewayUrl + "/tdtYnLogin/info";
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    async: false,
    beforeSend: function (request) {
      request.setRequestHeader('Tdt-S-Token', getYNToken());
      request.setRequestHeader('Tdt-S-App-Id',s_app_id );
    },
    success: function (res) {
      let data = res.data;
      if (data == null || data === 'null') {
        removeYNToken();
        Store.dispatch(removeUser());
        Store.dispatch(removeName());
      } else {
        Store.dispatch(setUser(data));
        Store.dispatch(setName(data.userName));
      }
    },
    error: function (err) {
      removeYNToken();
      Store.dispatch(removeUser());
      Store.dispatch(removeName());
    }
  });
}

export default App;
