import React from 'react';
import './AdvertisingBoard.css';

class AdvertisingBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            panelClass: "AdvertisingBoard",
            htmlString: ""
        }
    }

    componentDidMount() {
        let htmlStr =
            '<h3 style="text-align: center; font-size: 22px; font-weight: bold; line-height: 34px;">' +
            '关于调用“天地图·云南”平台资源服务使用注意事项的通知' +
            '</h3>' +
            '<p style="font-size: 14px; font-weight: bold; line-height: 26px;">' +
            '尊敬的“天地图·云南”用户：' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; font-weight: bold; line-height: 26px;">' +
            '您好!' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '为了加强地图管理，维护国家主权、安全和发展利益，促进地理信息产业健康发展，更好地服务于经济建设、社会发展和人民生活。根据《地图管理条例》第五十三条、《云南省测绘条例》第二十六条的规定和调用“天地图·云南”地图资源服务的相关要求，在调用“天地图·云南”或国家天地图的地图资源服务时，涉及到使用地图的应用系统、平台或APP等应用均需按条例要求在地图的适当位置显著标注最新审图号、“天地图·云南”图标(桌面应用软件调用除外)，标注位置可参考“天地图·云南”在线地图左下角样式(<a href="https://yunnan.tianditu.gov.cn/onlineMap/" style="text-decoration-line: underline" target="_self">在线地图</a>)。请各位用户尽快按相关要求完成标注，未按要求标注的用户，地图资源服务调用将被暂停并列入风险名单，风险名单内用户后续将无法调用“天地图·云南”相关地图资源服务。“天地图·云南”图标下载地址：' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '1、https://sso.ynmap.cn/staticSource/logo/tianditu.png,天地图logo（png格式）。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '2、https://sso.ynmap.cn/staticSource/logo/tianditu.svg，天地图logo（svg格式）。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '3、https://sso.ynmap.cn/staticSource/logo/approval.png，天地图审图号（png格式）。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '4、https://sso.ynmap.cn/staticSource/logo/approval.svg，天地图审图号（svg格式）。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '5、https://sso.ynmap.cn/staticSource/logo/tianditu_all.png，天地图logo+审图号（png格式）。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '6、https://sso.ynmap.cn/staticSource/logo/tianditu_all.svg，天地图logo+审图号（svg格式）。' +
            '</p>' +
            '<br style="height: 20px"/>' +
            '<div style="width: 100%; border-top: 1px solid #bbbbbb"/>' +
            '<br style="height: 20px"/>' +
            '<h3 style="text-align: center; font-size: 22px; font-weight: bold; line-height: 34px;">' +
            '“天地图·云南”平台资源服务升级公告' +
            '</h3>' +
            '<p style="font-size: 14px; font-weight: bold; line-height: 26px;">' +
            '尊敬的“天地图·云南”用户：' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; font-weight: bold; line-height: 26px;">' +
            '您好!' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '针对近期用户反馈的在调用“天地图·云南”服务资源时，出现访问速度慢，服务不稳定等问题，我院及时分析了原因，立即开展技术优化，对在线地图服务资源的调用方式、存储模式和用户体系均做了全面的升级，更新发布了新版在线地图服务资源的服务地址。为保障您所使用资源服务的稳定和“天地图·云南”公众服务的高效运行，请尽快登录<a href="https://yunnan.tianditu.gov.cn" style="text-decoration-line: underline" target="_self">云南省地理信息公共服务平台</a>，更新调整所使用的服务资源地址，避免因服务地址变化影响相关业务工作，有大批量资源调用需求的用户可申请专属服务通道，具体操作详见附件《云南省地理信息公共服务平台 (天地图·云南)开发者控制台用户操作手册v1.0》。非常感谢各位用户一直以来对天地图的关注和支持，给您带来的不便，敬请谅解。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;font-weight: bold;">' +
            '新版电子地图分为电子地图和电子地图注记两个服务，用户可根据情况使用。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px; ">' +
            '新版地图服务资源于即日起启用，旧版地图服务资源将于2023年9月7日关闭。请各位用户尽快切换至新版地图服务资源地址。新版地图服务资源调用可在地理信息公共服务平台门户网站的【本地服务资源&gt;本地资源申请】或【首页&gt;开发资源&gt;本地资源申请】中进行申请使用。' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '特此公告！' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '<strong>附件：</strong><a href="https://sso.ynmap.cn/staticSource/common/天地图·云南控制台操作手册v1.0.pdf" style="text-decoration-line: underline"  target="_blank">《云南省地理信息公共服务平台 (天地图·云南)开发者控制台用户操作手册v1.0》</a>' +
            '</p>' +
            '<p style="text-indent:2em; font-size: 14px; line-height: 26px;">' +
            '技术支持联系电话：0871-64166028' +
            '</p>' +
            '<p style="text-align: right; line-height: 26px;">' +
            '云南省地图院' +
            '</p>' +
            '<p style="text-align: right;line-height: 26px;">' +
            '2023年8月28日' +
            '</p>'
        this.setState({htmlString:htmlStr})
    }
    render() {
        return (
            <div className={this.state.panelClass}>
                {/*<img style={{width:60,height:20}} src={"https://sso.ynmap.cn/staticSource/logo/approval.png"}/>*/}
                <div className={"content"} dangerouslySetInnerHTML={{ __html: this.state.htmlString }}>
                </div>
                <i onClick={this.closeOnlineMapImport.bind()} className={"iconfont"}>&#xe696;</i>
            </div>
        );
    }

    openOnlineMap(){
        window.open("https://app.ynmap.cn/app/onlineMap/","_blank")
    }
    closeOnlineMapImport=()=>{
        this.setState({panelClass:"AdvertisingBoardHidden"});
    }
}

export default AdvertisingBoard;
