import React from 'react';
import './Header.css';
import HeaderNavigation from './HeaderNavigation.js';
import {
   getYNToken,
  getZWToken,
  removeYNToken,
  removeZWToken
} from '../../modules/CookieTools';

import $ from 'jquery';
import {Button, Carousel, Drawer, Popconfirm, Image} from 'antd';
import { Link } from 'react-router-dom';
import { Store } from '../../store/Store';
import defined from '../../modules/defined';
import {removeName, removeUser, setName, setUser} from '../../store/reducers/UserSore';
import {logoutApi} from "../../api/UserApi";
import {deploymentUrl, govUrl, loginUrl, publishSite, sAppId, siteValue} from "../../config/setting";

class Header extends React.Component {
  constructor(props) {
    super(props);
    window.isLoading = false;
    this.loginRegisterHiddenClass =
      'headerHintCon-login-register-vessel-hidden';
    this.loginRegisterShowClass = 'headerHintCon-login-register-vessel-show';

    this.userInfoHiddenClass = 'headerHintCon-userInfo-hidden';
    this.userInfoShowClass = 'headerHintCon-userInfo-show';

    this.loginHiddenClass = 'headerHintCon-login-vessel-hidden';
    this.loginShowClass = 'headerHintCon-login-vessel-show';

    this.registerHiddenClass = 'headerHintCon-register-vessel-hidden';
    this.registerShowClass = 'headerHintCon-register-vessel-show';

    this.state = {
      loginRegisterClass: this.loginRegisterShowClass,
      userInfoClass: this.userInfoHiddenClass,
      loginClass: this.loginHiddenClass,
      registerClass: this.registerHiddenClass,
      userInfoName: '',
      typeId: '',
      newsList: [],
      openConfirm: false,
      openDrawer: false,
    };
    window.isLoading = false;
  }

  componentDidMount() {
    let that = this;
    //获取国家用户信息---能获取到-国家用户登录；获取不到-未登录
    $.ajax({
      type: 'get',
      async: false,
      url: deploymentUrl + '/auth/tianditu/casInfo',
      success: function (res) {
        if (res.code === 200) {
          let loginEmail = res.data.loginEmail
          Store.dispatch(setUser(res.data));
          Store.dispatch(setName(loginEmail));
          //天地图登录
          that.setState({ userInfoName: loginEmail });
          that.setState({
            userInfoUrl: 'https://uums.tianditu.gov.cn/enterpriseInfo'
          });
          that.setState({ loginRegisterClass: that.loginRegisterHiddenClass });
          that.setState({ userInfoClass: that.userInfoShowClass });
        } else {
          that.verifyLogin()
        }
      },
      error: function (err) {
        that.verifyLogin()
      }
    });

    // 获取公告
    this.loadNewsColumnData();
  }

  //校验政务用户和天地图云南用户是否登录
  verifyLogin(){
    let userName = Store.getState().user.userName;
    if (defined(userName) && defined(getZWToken())) {
      //政务网登录
      this.setState({ userInfoName: userName });
      this.setState({
        userInfoUrl:
            'https://zwfw.yn.gov.cn/portal/#/ynuser-center/modify-personal-info'
      });
      this.setState({ loginRegisterClass: this.loginRegisterHiddenClass });
      this.setState({ userInfoClass: this.userInfoShowClass });
    } else if (defined(userName) && defined(getYNToken())) {
      //天地图云南登录
      this.setState({ userInfoName: userName });
      this.setState({
        userInfoUrl:
            'https://yunnan.tianditu.gov.cn/control/account'
      });
      this.setState({ loginRegisterClass: this.loginRegisterHiddenClass });
      this.setState({ userInfoClass: this.userInfoShowClass });
    } else {
      //没有登录
      this.setState({ loginRegisterClass: this.loginRegisterShowClass });
      this.setState({ userInfoClass: this.userInfoHiddenClass });
    }
  }

  //退出登录---按钮
  exitClick() {
    let userName = Store.getState().user.userName;
    //退出国家账号登录
    this.exitLoadTianditu();
   if (defined(userName) && defined(getZWToken())) {
      //政务网登录
      this.exitLoadZw();
    }else if (defined(userName) && defined(getYNToken())){
      this.exitLoadYNTdt()
    }
  }
  //退出国家天地图。
  exitLoadTianditu() {
    let that = this;
    $.ajax({
      url: deploymentUrl + '/auth/tianditu/casLogout?redirectUrl=' + publishSite,
      type: 'GET',
      dataType: 'json',
      async: true,
      success: function (data) {
        that.removeCookieLocal();
        window.open(publishSite, '_self');
      },
      error: function (err) {
        that.removeCookieLocal();
        window.open(publishSite, '_self');
      }
    });
  }

  //退出天地图云南
  exitLoadYNTdt(){
    logoutApi().then(res =>{
      this.removeCookieLocal();
    }).catch(err =>{
      console.log('退出天地图·云南失败', err);
    })
  }

  //退出政务。
  exitLoadZw() {
    let that = this;
    $.ajax({
      url: deploymentUrl + '/yntdtsite/ynzwsso/logout?token=' + getZWToken(),
      type: 'GET',
      dataType: 'json',
      async: true,
      xhrFields: {
        withCredentials: true // 这里设置了withCredentials
      },
      success: function (data) {
        that.removeCookieLocal();
        console.log('退出政务', data);
      },
      error: function (err) {
        console.log('退出政务失败', err);
      }
    });
  }

  //删除cookie--显示登陆注册
  removeCookieLocal() {
    this.setState({ loginRegisterClass: this.loginRegisterShowClass });
    this.setState({ userInfoClass: this.userInfoHiddenClass });
    removeZWToken();
    removeYNToken();
    Store.dispatch(removeUser());
    Store.dispatch(removeName());
  }

  //返回国家节点，退出云南节点账号
  confirm(e){
    this.setState({openConfirm: false});
    this. exitLoadYNTdt()
    window.open("https://www.tianditu.gov.cn/","_self")
  };


  //本省节点账号登录弹出提示框，否则直接跳转
  onBackCn(e){
    if (defined(getYNToken())) {
      this.setState({openConfirm: true});
    }else {
      this.setState({openConfirm: false});
      window.open("https://www.tianditu.gov.cn/","_self")
    }
  };

  render() {
    return (
      <div className={'header-con'}>
        <div className={'headerHint'}>
          <div className={'headerHintCon'}>
            <div className={'headerHintCon-news'}>
              <div title={'更多'} className={'headerHintCon-news-icon'}>
                <Link to={'/support/news'}>
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          '/staticImg/header/logo_trumpet.png'
                      }
                  />
                </Link>
              </div>
              <div className={'headerHintCon-news-con'}>
                {
                  <Carousel autoplay dots={false} dotPosition={'left'}>
                    {this.state.newsList.map((item, key) => (
                        <div key={key} className={'headerHintCon-news-con-item'}>
                          {item.noticeLink === '_self' ? (
                              <Link to={item.router}>
                                <strong>{'[ ' + item.year + ' ]'}</strong>
                                {item.noticeTitle}
                              </Link>
                          ) : (
                              <a href={item.noticeUrl} target={'_blank'}>
                                <strong>{'[ ' + item.year + ' ]'}</strong>
                                {item.noticeTitle}
                              </a>
                          )}
                        </div>
                    ))}
                  </Carousel>
                }
              </div>
            </div>
            <div className={'headerHintCon-login-register'}>
              {/*登录注册*/}
              <div className={this.state.loginRegisterClass}>
                <div className={'headerHintCon-login'}>
                  <span
                      onMouseLeave={this.loginMouseLeave.bind(this)}
                      onMouseOver={this.loginMouseOver.bind(this)}
                  >
                    {'登录'}
                  </span>
                  <div
                      onMouseLeave={this.loginMouseLeave.bind(this)}
                      onMouseOver={this.loginMouseOver.bind(this)}
                      className={this.state.loginClass}
                  >
                    <div className={'headerHintCon-login-vessel-icon'}>
                      <i className={'iconfont'}>&#xe616;</i>
                    </div>
                    <div className={'headerHintCon-login-vessel-test'}>
                      <div className={'headerHintCon-login-vessel-tdt'}>
                        <i className={'iconfont'}>&#xe612;</i>
                        {/*href={deploymentUrl + '/auth/tianditu/casLogin?sAppId=' + sAppId + '&redirectUrl=' + publishSite}*/}
                        <a
                            style={{color:"#7a7878",cursor: "not-allowed"}}
                            title={'正在对接中~'}
                        >
                          国家天地图用户
                        </a>
                      </div>
                      <div className={'headerHintCon-login-vessel-zw'}>
                        <i className={'iconfont'}>&#xe612;</i>
                        <a
                            href={
                                loginUrl + '/login?s_app_id=DLXXFW_APP_YN_001&backUrl=' +
                                publishSite
                            }
                        >
                          天地图云南用户
                        </a>
                      </div>
                      <div className={'headerHintCon-login-vessel-zw'}>
                        <i className={'iconfont'}>&#xe612;</i>
                        <a
                            href={
                                'https://zwfw.yn.gov.cn/portal/#/login?C-App-Id=DLXXFW_APP_YN_001&backUrl=' +
                                publishSite
                            }
                        >
                          政务用户
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'headerHintCon-register'}>
                  <span
                      onMouseLeave={this.registerMouseLeave.bind(this)}
                      onMouseOver={this.registerMouseOver.bind(this)}
                  >
                    {'注册'}
                  </span>
                  <div
                      onMouseLeave={this.registerMouseLeave.bind(this)}
                      onMouseOver={this.registerMouseOver.bind(this)}
                      className={this.state.registerClass}
                  >
                    <div className={'headerHintCon-register-vessel-icon'}>
                      <i className={'iconfont'}>&#xe616;</i>
                    </div>
                    <div className={'headerHintCon-register-vessel-test'}>
                      <div className={'headerHintCon-register-vessel-tdt'}>
                        <i className={'iconfont'}>&#xe611;</i>
                        {/*href="https://uums.tianditu.gov.cn/register"*/}
                        <a style={{color:"#7a7878",cursor: "not-allowed"}} title={'正在对接中~'} >
                          国家天地图注册
                        </a>
                      </div>
                      <div className={'headerHintCon-register-vessel-zw'}>
                        <i className={'iconfont'}>&#xe611;</i>
                        <a
                            href={
                                loginUrl + '/register?backUrl=https://yunnan.tianditu.gov.cn&refer=NA'
                            }
                        >
                          天地图云南注册
                        </a>
                      </div>
                      <div className={'headerHintCon-register-vessel-zw'}>
                        <i className={'iconfont'}>&#xe611;</i>
                        <a
                            href={
                              'https://zwfw.yn.gov.cn/portal/#/regist?backUrl=https://yunnan.tianditu.gov.cn&refer=NA'
                            }
                        >
                          政务注册
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*用户信息*/}
              <div className={this.state.userInfoClass}>
                <a target={'_blank'} href={this.state.userInfoUrl}>
                  {this.state.userInfoName}
                </a>
                <i
                    onClick={this.exitClick.bind(this)}
                    title={'退出'}
                    className={'iconfont'}
                >
                  &#xe613;
                </i>
              </div>
              {/*返回国家节点*/}
              <div className={'headerHintCon-returnMasterNode'}>
                <Popconfirm
                    open={this.state.openConfirm}
                    title="当前账号为省级节点账号，返回国家节点会自动退出省级节点账号"
                    onCancel={() => {
                      this.setState({openConfirm: false})
                    }}
                    cancelText="取消"
                    onConfirm={this.confirm.bind(this)}
                    okText="确定"
                >
                  <a title={'返回国家节点'}>
                    <img
                        onClick={this.onBackCn.bind(this)}
                        src={
                            process.env.PUBLIC_URL +
                            '/staticImg/header/loge_countryHome.png'
                        }
                    />
                  </a>
                </Popconfirm>
              </div>
            </div>

            <div style={{paddingRight: "10px", paddingLeft: "10px"}}>|</div>

            <div className={'headerHintCon-aboutUser'}>
                <span onClick={() => {
                  this.setState({openDrawer: true})
                }}>关于用户</span>
            </div>
          </div>
        </div>

        <Drawer
            title={`关于用户`}
            placement="right"
            size={"370px"}
            open={this.state.openDrawer}
            onClose={() => {
              this.setState({openDrawer: false})
            }}
        >
          <p className={'aboutUser-problem'}>1、为什么会有两个用户体系？</p>
          <p className={"aboutUser-answer"}>为了方便用户管理以及信息安全，从2024年12月起，天地图·云南将建设使用本地用户体系。</p>
          <p className={'aboutUser-problem'}>2、已注册用户如何继续使用？</p>
          <p className={'aboutUser-answer'}>系统更新升级前，用户注册登录均为天地图国家节点注册登录。从2024年12月系统更新升级起，天地图·云南将使用本地用户体系，系统会自动将已注册用户同步至本地用户体系。
            届时，已注册用户将会拥有相同手机号、邮箱的国家节点账号以及天地图·云南账号，老用户可以正常使用已注册账号在国家节点登录，同时可以使用手机号验证码登录天地图·云南，登录天地图·云南后需根据提示完善用户信息方可继续使用。
          </p>
          <p className={'aboutUser-answer'}>使用流程图如下：</p>
          <Image width={310} src={process.env.PUBLIC_URL + '/staticImg/header/user_flow.jpg'}/>
          <p className={'aboutUser-problem'}>3、新用户如何使用？</p>
          <p className={'aboutUser-answer'}>天地图·云南用户注册地址：<br/>
            <a href={loginUrl + "/register"} target={"_blank"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{loginUrl + "/register"}</a>
          </p>
          <p className={'aboutUser-problem'}>4、如何使用本地资源？</p>
          <p className={'aboutUser-answer'}>在官网首页点击“本地资源申请”，使用天地图·云南账号登录开发者控制台，到个人中心进行账号认证，然后到应用管理中创建应用及申请资源，公共应用会自动审核，定制化应用需等待人工审核，审核通过后即可使用。</p>
          <p className={'aboutUser-problem'}>5、建议</p>
          <p className={'aboutUser-answer'}>为了方便您的账号记忆和管理，建议您使用相同的邮箱和手机号码注册国家天地图节点账号及省级天地图节点账号。</p>
        </Drawer>

        <div className={'headerTitle'}>
          <div className={'headerTitleCon'}>
          <img src={process.env.PUBLIC_URL + '/staticImg/header/logo_ynmap.svg'}/>
          <img src={process.env.PUBLIC_URL + '/staticImg/header/logo_ynmap.png'}/>
          </div>
        </div>
        <HeaderNavigation />
      </div>
    );
  }

  loadNewsColumnData() {
    fetch(
      deploymentUrl +
        '/tdtyns/TdtNotice/list?pageNum=1&pageSize=8&siteId=' +
        siteValue,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        mode: 'cors',
        cache: 'default'
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.code !== 200) {
          alert('查询新闻失败');
          return;
        }
        let rows = data.rows;

        for (let item of rows) {
          item.year = item.noticeDate.substring(0, 10);
          switch (item.noticeType) {
            case '更新日志': {
              item.router = '/supportInfo/logUpdate/' + item.noticeId;
              break;
            }
            case '新闻公告': {
              item.router = '/supportInfo/news/' + item.noticeId;
              break;
            }
          }
        }

        this.setState({ newsList: rows });
      });
  }

  //登录
  loginMouseOver(target) {
    this.setState({ loginClass: this.loginShowClass });
  }

  loginMouseLeave(target) {
    this.setState({ loginClass: this.loginHiddenClass });
  }

  //注册
  registerMouseOver(target) {
    this.setState({ registerClass: this.registerShowClass });
  }

  registerMouseLeave(target) {
    this.setState({ registerClass: this.registerHiddenClass });
  }
}

export default Header;
