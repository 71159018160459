import React from 'react';
import './Example.css'
import Footer from '../../../components/footer/Footer';

import * as CodeMirror from 'codemirror/lib/codemirror'
import * as Clipboard from 'clipboard/dist/clipboard'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import {deploymentUrl, siteValue, staticSourceUrl} from "../../../config/setting";


class Example extends React.Component {
    constructor(props) {
        super(props);

        this.ExampleContendShowClass = "Example-contend-vessel-show";
        this.ExampleContendHiddenClass = "Example-contend-vessel-hidden";

        this.ExamplePopupShowClass = "Example-popup-show";
        this.ExamplePopupHiddenClass = "Example-popup-hidden";

        this.rightIcon = "<i class='iconfont'>&#xe645;</i>";
        this.downIcon = "<i class='iconfont'>&#xe644;</i>";

        this.secondLevelUn = "Example-tabs-item-secondLevel-un";
        this.secondLevelHas = "Example-tabs-item-secondLevel-has";

        this.editor = "editor";
        this.editTip = "editorTip";
        this.explaincontent = "explain-content";
        this.explaincontentTip = "explain-contentTip";

        this.state = {
            exampleType: [],
            downRight:this.rightIcon,
            editorActive:false,
            selectedCodeActive:true,
            selectedExplainActive:false,
            insertHtml:"",
            ExampleContendClass: this.ExampleContendShowClass,
            ExamplePopupClass: this.ExamplePopupHiddenClass,

        };

        this.CodeMirrorEditor = undefined;

        this.activeOneIndex = 0;
        this.activerightIndex = 0;
        this.activeSecondLevel = "0_0";
        this.activeContent  = false;

        let html_el = document.getElementsByTagName('html')[0];
        html_el.classList.add('apiHtml');

    }

    componentWillUnmount (){
        let html_el = document.getElementsByTagName('html')[0];
        html_el.classList.remove('apiHtml');
    }

    componentDidMount(){
        let url = deploymentUrl + '/tdtyns/APIexeType/list?delFlag=1&siteId=' + siteValue;

        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            let url1 = deploymentUrl + '/tdtyns/TdtApiExample/list?delFlag=1&siteId=' + siteValue;
            fetch(url1, {
                method:'GET',
                headers:{
                    'Content-Type':'application/json;charset=UTF-8'
                },
                mode:'cors',
                cache:'default',
            }).then(res => res.json()).then(dataT => {
                if (data.code !== 200) {
                    alert("查询数据失败");
                    return;
                }
                let exampleTypes = [];
                for (let item of data.data) {
                    if (item.parentId === 0) {
                        let temp = {};
                        temp.stair = item;
                        temp.secondLevel = [];
                        for (let itemT of data.data) {
                            if (item.id === itemT.parentId) {
                                let tempT = {};
                                tempT.headline = itemT;
                                tempT.kernel = [];
                                for (let itemL of dataT.rows) {
                                    if (itemL.typeId === itemT.id) {
                                        tempT.kernel.push(itemL);
                                    }
                                }
                                temp.secondLevel.push(tempT);
                            }
                        }
                        exampleTypes.push(temp);
                    }
                }
                this.setState({
                    exampleType: exampleTypes,
                    editorActive:false,
                });
                this.stairClick(0,exampleTypes[0].secondLevel.length,undefined)
            });

        });

        let myTextarea = document.getElementById('editor');
        this.CodeMirrorEditor = CodeMirror.fromTextArea(myTextarea, {
            mode:'htmlmixed',//编辑器语言
            theme:'monokai', //编辑器主题
            extraKeys: {"Ctrl": "autocomplete"},//ctrl可以弹出选择项
            lineNumbers: true, //显示行号
            smartIndent:true,
            indentUnit:10
        });
        this.CodeMirrorEditor.setSize('auto','100%');
        this.CodeMirrorEditor.setValue(
            '<!DOCTYPE html>\n' +
            '<html>\n' +
            '  <head>\n' +
            '    <meta charset="utf-8" />\n' +
            '  </head>\n' +
            '  <body>\n' +
            '  </body>\n' +
            '</html>'
        );

        let that = this;
        //复制相关代码
        let clipboard = new Clipboard('#copy',{
            text: function() {
                console.log('that.CodeMirrorEditor.getValue() : ',that.CodeMirrorEditor.getValue().toString());
                return that.CodeMirrorEditor.getValue().toString();
            }
        });
        clipboard.on('success', function(e) {
            console.log(e);
            alert('复制成功');
        });

    }


    //点击了第几个item后的效果
    refreshContentClick(index,item){
        this.setState({
            insertHtml:item.exampleExplain,
        });

        fetch(staticSourceUrl + item.exampleCode, {
            method: 'GET',
            mode: "cors",
        }).then(res => res.text()).then(data => {
            this.editorDefaultValue = data;
            this.CodeMirrorEditor.setValue(data);
            //解决加载不显示问题
            var that = this;
            setTimeout(function() {
                that.CodeMirrorEditor.refresh();
            },1);

            this.setState({ExampleContendClass:this.ExampleContendHiddenClass});
            this.setState({ExamplePopupClass:this.ExamplePopupShowClass});
            //不能放到外边
            this.setState({
                editorActive:true,
                selectedCodeActive:true,
                selectedExplainActive:false,
            });
            this.codeRun();
        });
    }

    //点击代码
    showCode(){
        this.setState({
            selectedCodeActive:true,
            selectedExplainActive:false,
        })
    }

    //点击说明
    showExplain(){
        this.setState({
            selectedCodeActive:false,
            selectedExplainActive:true,
        })
    }

    codeRun(){
        let exampleCon = null;
        let exampleBox = document.getElementById('codeResultCon');
        let example = document.createElement('iframe');

        example.id = 'codeResult';
        example.className='code-result';
        example.name="code-frame";

        exampleBox.innerHTML = '';
        exampleBox.appendChild(example);
        exampleCon = example.contentWindow.document;
        if (exampleCon != null) {
            exampleCon.designMode = "on"; //文档进入可编辑模式
            exampleCon.open(); //打开流
            exampleCon.write(this.CodeMirrorEditor.getValue());
            exampleCon.close(); //关闭流
            exampleCon.designMode ="off"; //文档进入非可编辑模式
        }
    }

    codeClear(){
        this.CodeMirrorEditor.setValue('');
    }

    codeReset(){
        this.CodeMirrorEditor.setValue(this.editorDefaultValue);
    }
    //返回按钮
    backMainClick(){
        this.setState({ExampleContendClass:this.ExampleContendShowClass});
        this.setState({ExamplePopupClass:this.ExamplePopupHiddenClass});
        //不能放到外边
        this.setState({editorActive:false,});
    }
    render() {
        return (
            <div className={'Example'}>
                {/*左边导航*/}
                <div className={"Example-tabs"}>
                    {
                        this.state.exampleType.map((item, index)=>
                            <div className={"Example-tabs-item"} key={index}>
                                {/*一级*/}
                                <div data-index = {false} onClick={this.stairClick.bind(this,index,item.secondLevel.length)} className={"Example-tabs-item-stair"}>
                                    <div className={"Example-tabs-item-stair-a"} dangerouslySetInnerHTML={{__html:"<i class='iconfont'>" + item.stair.icon + "</i>"}}/>
                                    <span>{item.stair.typeName}</span>
                                    <div className={"Example-tabs-item-stair-b"}
                                         dangerouslySetInnerHTML={{__html: this.state["downRight_" + index] === undefined ? this.rightIcon : this.state["downRight_" + index]}}/>
                                </div>
                                {/*二级*/}
                                <div className={"Example-tabs-item-secondLevel"}>
                                    {
                                        item.secondLevel.map((itemT,key)=>
                                            <span onClick={this.locationSecondLevel.bind(this,index,key)}
                                                  className={this.state["secondLevel_" + index + "_" + key]===undefined?"Example-tabs-item-secondLevel-un":this.state["secondLevel_" + index + "_" + key]} key={key}>{itemT.headline.typeName}</span>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={"Example-contend"}>
                    <div className={this.state.ExampleContendClass}>
                        {/*中间内容*/}
                        <div className={"Example-contend-list"}>
                            <div className={"Example-contend-lists"}>
                                <div className={"Example-contend-lists-location"}>
                                    {"首页 > 开发资源 > API 示例"}
                                </div>
                                {
                                    this.state.exampleType.map((item, index)=>
                                        <div className={"Example-contend-list-item"} key={index}>
                                            {
                                                item.secondLevel.map((itemT,key)=>
                                                    <div key={key}>
                                                        <div className={"Example-contend-list-item-title"}>
                                                            <div className={"Example-contend-list-item-line"}>
                                                                <i>
                                                                    <span>{itemT.headline.typeName}</span>
                                                                </i>
                                                            </div>
                                                        </div>
                                                        <div className={"Example-contend-list-item-con"}>
                                                            {
                                                                itemT.kernel.map((itemZ,index1)=>
                                                                    <div className={"is-hover-shadow"} key={index1} onClick={this.refreshContentClick.bind(this,index1,itemZ)}>

                                                                        <div className={"box-card-header"}>
                                                                            <img src={staticSourceUrl + itemZ.exampleImg}></img>
                                                                        </div>

                                                                        <div className={"is-body"}>
                                                                            <div className={"is-text"}>
                                                                                <span>
                                                                                    {itemZ.exampleName}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <Footer version={1}/>
                        </div>
                        {/*右边导航*/}
                        <div className={"Example-contend-right"}>
                            <div className={"Example-contend-right-line"}>
                                <div className={"Example-contend-right-vessel"}>
                                    {
                                        this.state.exampleType.map((item, index)=>
                                            <div data-index = {false} onClick={this.stairClick.bind(this,index,item.secondLevel.length)} className={"Example-contend-right-vessel-item"} key={index}>
                                                <div className={"Example-contend-right-vessel-item-point"}>
                                                    <i className={"iconfont"}>&#xe62c;</i>
                                                </div>
                                                <div className={"Example-contend-right-vessel-item-des"}>
                                                    <span>{item.stair.typeName}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*彈窗*/}
                    <div className={this.state.ExamplePopupClass}>
                        <div className={'code-run-result-panel'} id={'codeResultCon'}>
                        </div>
                        <div className={'editor-panel'}>
                            <div className={'editor-panel-bar'}>
                                <div className={this.state.selectedCodeActive?'editor-panel-bar-code-has':'editor-panel-bar-code-un'} onClick={this.showCode.bind(this)}>
                                    <i className={'iconfont'}>&#xe802;</i>
                                    <span>代码</span>
                                </div>
                                <div className={this.state.selectedExplainActive?'editor-panel-bar-code-has':'editor-panel-bar-code-un'} onClick={this.showExplain.bind(this)}>
                                    <i className={'iconfont'}>&#xe65a;</i>
                                    <span>说明</span>
                                </div>
                                <div className={this.state.selectedCodeActive?'editor-panel-bar-control-show':'editor-panel-bar-control-hidden'}>
                                    <div className={'editor-panel-bar-control-run'} onClick={this.codeRun.bind(this)}><span>运行</span></div>
                                    <div className={'editor-panel-bar-control-run'} id={'copy'}><span>复制</span></div>
                                    <div className={'editor-panel-bar-control-run'} onClick={this.codeReset.bind(this)}><span>重置</span></div>
                                    <div className={'editor-panel-bar-control-run'} onClick={this.codeClear.bind(this)}><span>清除</span></div>
                                </div>
                                <div className={'editor-panel-bar-back'} onClick={this.backMainClick.bind(this)}><span>返回</span></div>
                            </div>

                            <div className = {this.state.selectedCodeActive?'editor-panel-code-show':'editor-panel-code-hidden'}>
                                <textarea className="editor-panel-code-text" id="editor" name="code"/>
                            </div>

                            <div className = {this.state.selectedExplainActive?'editor-panel-code-show':'editor-panel-code-hidden'}>
                                <div dangerouslySetInnerHTML={{__html: this.state.insertHtml}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    //打开第I个，关闭上一个
    stairClick(index,len,target){
        this.openLeftNav(index,len);
        this.highlightNav(index,len);
        this.locationSecondLevel(index,0,undefined);
    }

    //打开左边导航第i个
    openLeftNav(index,len){
        let elements = document.getElementsByClassName("Example-tabs-item-stair");
        let nowElement = elements[index];
        let oldElement = elements[this.activeOneIndex];

        if (nowElement.getAttribute("data-index") === "true") {
            return;
        }

        //关闭之前的
        oldElement.parentElement.childNodes[1].style.height = "0px";
        oldElement.setAttribute("data-index",false);
        this.setState({
            ["downRight_" + this.activeIndex]:this.rightIcon,
            editorActive:false,
        });

        //打开现在的
        nowElement.parentElement.childNodes[1].style.height = len * 36 + "px";
        nowElement.setAttribute("data-index",true);
        this.setState({
            ["downRight_" + index]:this.downIcon,
            editorActive:false,
        });
        this.activeOneIndex = index;
    }

    //高亮右边导航第i个
    highlightNav(index,len){
        let elements = document.getElementsByClassName("Example-contend-right-vessel-item");
        let nowElement = elements[index];
        let oldElement = elements[this.activerightIndex];

        if (nowElement.getAttribute("data-index") === "true") {
            return;
        }

        //关闭之前的
        oldElement.childNodes[0].firstChild.style.color = "#e4e7ed";
        oldElement.childNodes[1].firstChild.style.color = "#bbbbbb";
        oldElement.setAttribute("data-index",false);

        //打开现在的
        nowElement.childNodes[0].firstChild.style.color = "rgba(0,84,146,0.6)";
        nowElement.childNodes[1].firstChild.style.color = "rgba(0,84,146,0.6)";
        nowElement.setAttribute("data-index",true);
        this.activerightIndex = index;
    }

    //定位到二级导航所在位置，并高亮
    locationSecondLevel(index,key,target){
        this.backMainClick();
        //选中
        let navElements = document.getElementsByClassName("Example-tabs-item-secondLevel");
        let nowNavElement = navElements[index].childNodes[key];
        console.log("nowNavElement",nowNavElement);

        this.setState({
            ["secondLevel_" + this.activeSecondLevel]: this.secondLevelUn,
            editorActive:false,
        });

        this.setState({
            ["secondLevel_" + index + "_" + key]: this.secondLevelHas,
            editorActive:false,
        });
        this.activeSecondLevel = index + "_" + key;

        // 定位-----------内容
        let listElement = document.getElementsByClassName("Example-contend-list")[0];
        let conElements = document.getElementsByClassName("Example-contend-list-item");
        let contentElement = conElements[index].childNodes[key];
        listElement.scrollTop = (contentElement.offsetTop - listElement.offsetTop) -10;

        if (this.activeContent) {
            this.activeContent.style.border = "#bbbbbb";
            this.activeContent.style.color = "#bbbbbb";
        }

        let titleE = contentElement.firstChild.firstChild.firstChild.firstChild;
        titleE.style.border = "1px solid rgb(243, 152, 0)";
        titleE.style.color = "rgb(243, 152, 0)";
        this.activeContent = titleE;
    }
}

export default Example;
