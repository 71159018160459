import React from 'react';
import Search from '../../common/search/Search';
import PagingButton from '../../common/paging/PagingButton';
import './SoftwareResource.css';
import QRCode from 'qrcode.react';
import './SourcesList.css';
import Footer from "../../footer/Footer";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {deploymentUrl, staticSourceUrl, siteValue} from "../../../config/setting";
class SoftwareResource extends React.Component {
    constructor(props) {
        super(props);
        this.sourceClassifyHiddenClass = "sources-list-classify-hidden";
        this.sourceClassifyShowClass = "sources-list-classify-show";

        this.selectedClass = "SoftwareResource-list-select-serviceType-selected";
        this.unselectedClass = "SoftwareResource-list-select-serviceType-unselected";

        this.SoftwareResourceShowClass = "SoftwareResource-list-vessel-show";
        this.SoftwareResourceHiddenClass = "SoftwareResource-list-vessel-hidden";

        this.showPopupClass = "SoftwareResource-popupShow";
        this.hiddenPopupClass = "SoftwareResource-popupHidden";

        this.len = 2;
        this.timer = undefined;

        this.state = {
            sourceClassifyClass: this.sourceClassifyShowClass,
            title: '',
            resourceType:[],

            popupClass: this.hiddenPopupClass,
            SoftwareResourceClass: this.SoftwareResourceShowClass,

            software_type_name: "软件类型：",
            software_type: [],

            system_name: "软件系统：",
            system_system: [],

            release_mechanism_name: "",
            release_mechanism: [],

            times_name: "发布时间：",
            times: [],

            resourceList: [],

            popupData: {},
            popupTitle: "",
            download_url: "",
            particular_img: [],
            application_introduced: "",
            version: "",
            developer: "",
            keywords: "",

            imgWidth: 0,
        };
        this.activeTypeIndex = 0;
        this.activeType = false;
        this.activeCoverageIndex = 0;
        this.activeCoverage = false;
        this.activeYearIndex = 0;
        this.activeYear = false;

        this.queryParam = {
            softwareType: null,
            softwareSystem: null,
            releaseTime: null,
            keyword:null
        }
    }


    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/body/sources/ResourceType.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({resourceType: data.items,title:data.title});
        });

        //获取软件分类
        fetch(deploymentUrl + '/tdtyns/TdtSoftwareType/list?delFlag=1&siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({software_type: data.rows});
        });

        fetch(deploymentUrl + '/tdtyns/TdtSoftwareSystem/list?delFlag=1&siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({system_system: data.rows});
        });

        fetch(deploymentUrl + '/tdtyns/TdtSoftwareResource/getYearList?siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("年份查询失败");
                return;
            }
            if (data.data.length !== 0) {
                let rows = data.data.sort().reverse();
                this.setState({times: rows})
            }
        });
    }

    //选择应用领域
    serviceTypeSelectedClick(item,key, target) {
        if (this.activeTypeIndex === key) {
            if (this.activeType) {
                this.setState({["software_type_color_" + key]: this.unselectedClass});
                this.queryParam.softwareType = null;
                this.activeType = false;
                this.activeTypeIndex = key;
            }else {
                this.setState({["software_type_color_" + key]: this.selectedClass});
                this.queryParam.softwareType = item.id;
                this.activeType = true;
                this.activeTypeIndex = key;
            }
        }else {
            this.setState({["software_type_color_" + this.activeTypeIndex]: this.unselectedClass});
            this.setState({["software_type_color_" + key]: this.selectedClass});
            this.queryParam.softwareType = item.id;
            this.activeType = true;
            this.activeTypeIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择资源类别
    coverageAreaSelectedClick(item,key, target) {
        if (this.activeCoverageIndex === key) {
            if (this.activeCoverage) {
                this.setState({["system_color_" + key]: this.unselectedClass});
                this.queryParam.softwareSystem = null;
                this.activeCoverage = false;
                this.activeCoverageIndex = key;
            }else {
                this.setState({["system_color_" + key]: this.selectedClass});
                this.queryParam.softwareSystem = item.id;
                this.activeCoverage = true;
                this.activeCoverageIndex = key;
            }
        }else {
            this.setState({["system_color_" + this.activeCoverageIndex]: this.unselectedClass});
            this.setState({["system_color_" + key]: this.selectedClass});
            this.queryParam.softwareSystem = item.id;
            this.activeCoverage = true;
            this.activeCoverageIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择年份
    yearSelectedClick(item,key, target) {
        if (this.activeYearIndex === key) {
            if (this.activeYear) {
                this.setState({["times_color_" + key]: this.unselectedClass});
                this.queryParam.releaseTime = null;
                this.activeYear = false;
                this.activeYearIndex = key;
            }else {
                this.setState({["times_color_" + key]: this.selectedClass});
                this.queryParam.releaseTime = item + "-1-1";
                this.activeYear = true;
                this.activeYearIndex = key;
            }
        }else {
            this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
            this.setState({["times_color_" + key]: this.selectedClass});
            this.queryParam.releaseTime = item + "-1-1";
            this.activeYear = true;
            this.activeYearIndex = key;
        }
        this.queryParam.keyword = null;
        //根据条件查询
        this.getPageAmount();
    };

    render() {
        return (
            <div className={'sources-list-container'}>
                <div className={this.state.sourceClassifyClass}>
                    <div className={"sources-list-classify-vessel"}>
                        <div className={"sources-list-classify-title"}>
                            <span>{this.state.title}</span>
                        </div>
                        <div id={"sources-list-classify-con"} className={"sources-list-classify-con"}>
                            {
                                this.state.resourceType.map((item, key) =>
                                    <Link style={{background:key===1?"#f39800":false}} key={key} to={item.href}>
                                        {item.name}
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div id={"sources-list-content"} className={"sources-list-content"}>
                    <div className={'SoftwareResource-list'}>
                        <div className={'SoftwareResource-list-title'}>
                            {"资源下载 > 软件"}
                        </div>
                        <div className={this.state.SoftwareResourceClass}>
                            <div className={'SoftwareResource-list-select'}>
                                <div className={"SoftwareResource-list-select-serviceType-line"}>
                                    <div className={"SoftwareResource-list-select-serviceType"}>
                                <span className={"SoftwareResource-list-select-serviceType-name"}>
                                    {this.state.software_type_name}
                                </span>
                                    </div>
                                    <div className={"SoftwareResource-list-select-serviceType-con"}>
                                        {
                                            this.state.software_type.map((item, key) =>
                                                    <span onClick={this.serviceTypeSelectedClick.bind(this, item,key)}
                                                          className={this.state["software_type_color_" + key]} key={key}>
                                        {item.typeName}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"SoftwareResource-list-select-serviceType-line"}>
                                    <div className={"SoftwareResource-list-select-serviceType"}>
                                <span className={"SoftwareResource-list-select-serviceType-name"}>
                                    {this.state.system_name}
                                </span>
                                    </div>
                                    <div className={"SoftwareResource-list-select-serviceType-con"}>
                                        {
                                            this.state.system_system.map((item, key) =>
                                                    <span onClick={this.coverageAreaSelectedClick.bind(this, item,key)}
                                                          className={this.state["system_color_" + key] === undefined ? this.unselectedClass : this.state["system_color_" + key]}
                                                          key={key}>
                                        {item.typeName}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"SoftwareResource-list-select-serviceType-line"}>
                                    <div className={"SoftwareResource-list-select-serviceType"}>
                                <span className={"SoftwareResource-list-select-serviceType-name"}>
                                    {this.state.times_name}
                                </span>
                                    </div>
                                    <div className={"SoftwareResource-list-select-serviceType-con"}>
                                        {
                                            this.state.times.map((item, key) =>
                                                    <span onClick={this.yearSelectedClick.bind(this, item,key)}
                                                          className={this.state["times_color_" + key]} key={key}>
                                        {item + "年"}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'SoftwareResource-list-search'}>
                                <Search search={this.searchClick} onRef={(ref) => {this.childSearch = ref;}}/>
                            </div>
                            <div className={'SoftwareResource-list-content'}>
                                <div className={'SoftwareResource-list-content-list-items'}>
                                    {
                                        this.state.resourceList.map((item, key) =>
                                            <div className={"SoftwareResource-list-content-list-item"} key={key}>
                                                <div className="SoftwareResource-list-content-list-item-title">
                                                    <p className="title">{item.softwareTitle}</p>
                                                    <span onClick={this.lookOver.bind(this, item)}
                                                          className="detail">查看&gt;&gt;</span>
                                                </div>
                                                <div className={"SoftwareResource-list-content-list-item-con"}>
                                                    <img onClick={this.lookOver.bind(this, item)} src={staticSourceUrl + item.titleImg}/>
                                                    <div className={"SoftwareResource-list-content-list-item-con-des"}>
                                                        <div className={"SoftwareResource-list-content-list-item-con-des_"}>
                                                            <span>发布时间：</span>
                                                            <p>{item.releaseTime}</p>
                                                        </div>
                                                        <div className={"SoftwareResource-list-content-list-item-con-des_"}>
                                                            <span>摘要信息：</span>
                                                            <p>{item.softwareAbstract}</p>
                                                        </div>
                                                        <div className={"SoftwareResource-list-content-list-item-con-des_"}>
                                                            <span>关键字：</span>
                                                            <div className={"SoftwareResource-list-content-list-item-con-des_a"}>
                                                                {
                                                                    item.keyword.split(/[,| ，]/g).map((itemT, index) =>
                                                                        <span key={index}>{itemT}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"SoftwareResource-list-content-list-item-con-des_"}>
                                                            <span>系统：</span>
                                                            <p>{this.systemTypeMapping(item.softwareSystem)}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"SoftwareResource-list-content-list-item-con-show"}>
                                                        <div className={"SoftwareResource-list-content-list-item-con-show-QR"}>
                                                            <div
                                                                className={"SoftwareResource-list-content-list-item-con-show-QR_"}>扫<br/>码<br/>下<br/>载
                                                            </div>
                                                            <div className={"SoftwareResource-list-content-list-item-con-show-QR_a"}>
                                                                { item.externalLinks !== undefined && item.externalLinks==="_self"?
                                                                    <QRCode
                                                                        value={staticSourceUrl + item.downloadUrl}// 生成二维码的内容
                                                                        size={95} // 二维码的大小
                                                                        fgColor="#000000" // 二维码的颜色
                                                                    />:
                                                                    <QRCode
                                                                        value={item.downloadUrl}// 生成二维码的内容
                                                                        size={95} // 二维码的大小
                                                                        fgColor="#000000" // 二维码的颜色
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"SoftwareResource-list-content-list-item-con-show-count"}>
                                                            <div title={"查看量"}
                                                                 className={"SoftwareResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe601;</i>
                                                                <span>{item.viewCount}</span>
                                                            </div>
                                                            <div title={"下载量"}
                                                                 className={"SoftwareResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe6cd;</i>
                                                                <span>{item.downloadCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div id={"SoftwareResource-list-content-pagingButton"}
                                     className={'SoftwareResource-list-content-pagingButton'}>
                                    <PagingButton pagingQuery={this.pagingQuery.bind(this)}
                                                  getPageAmount={this.getPageAmount.bind(this)} onRef={(ref) => {this.child = ref;}}/>
                                </div>
                            </div>
                        </div>
                        {/*弹窗*/}
                        <div className={this.state.popupClass}>
                            <div className={"SoftwareResource-popup-title"}>
                                <div>{this.state.popupTitle}</div>
                                <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                            </div>
                            <div className={"SoftwareResource-popup-Vessel"}>
                                <div className={"SoftwareResource-popup-con-but"}>
                                    {
                                        this.state.popupData.externalLinks !== undefined&&this.state.popupData.externalLinks==="_self"?
                                            <a onClick={this.downloadClick.bind(this,this.state.popupData.downloadUrl,this.state.popupData.id)} className={"SoftwareResource-popup-but-downloadJpg"}>
                                            {/*<a href={deploymentUrl + "/common/download/tdtresource?name="+ this.state.popupData.downloadUrl.replaceAll(/\\/g,'/') +"&id=" + this.state.popupData.id+"&downType=2"} className={"SoftwareResource-popup-but-downloadJpg"}>*/}
                                                <div>下载</div>
                                                <i className={"iconfont"}>&#xe62b;</i>
                                            </a>:
                                            <a href={this.state.popupData.externalLinks !== undefined?this.state.popupData.downloadUrl:"#"} target={"blank"}
                                            // <a href={this.state.popupData.externalLinks !== undefined?this.state.popupData.downloadUrl.replaceAll(/\\/g,'/'):"#"} target={"blank"}
                                               className={"SoftwareResource-popup-but-downloadJpg"}>
                                                <div>下载</div>
                                                <i className={"iconfont"}>&#xe62b;</i>
                                            </a>
                                    }

                                </div>
                                <div className={"SoftwareResource-popup-con-img-vessel"}>
                                    <div className={"SoftwareResource-popup-con-img"}>
                                        <div className={"SoftwareResource-popup-con-info-img"}>
                                            <div style={{width:this.state.imgWidth}}>
                                                {
                                                    this.state.particular_img.map((itemT,key1)=>
                                                        <img src={staticSourceUrl + itemT} key={key1}/>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className={"SoftwareResource-popup-con-info-developer"}>
                                            <span><strong>{"开发者："}</strong></span>
                                            {this.state.developer}
                                        </div>
                                        <div className={"SoftwareResource-popup-con-info-developer"}>
                                            <span><strong>{"版本："}</strong></span>
                                            {this.state.version}
                                        </div>
                                        <div className={"SoftwareResource-popup-con-info-developer"}>
                                            <span><strong>{"关键字："}</strong></span>
                                            {this.state.keywords}
                                        </div>
                                        <div className={"SoftwareResource-popup-con-info-developer"}>
                                            <span><strong>{"关于此应用："}</strong></span>
                                            {this.state.application_introduced}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        );
    }
    //系统映射
    systemTypeMapping(softwareSystem,target){
        for (let systemType of this.state.system_system) {
            if (systemType.id === parseInt(softwareSystem)) {
                softwareSystem = systemType.typeName;
            }
        }
        return softwareSystem;
    }

    //下载---内链
    downloadClick(downloadUrl,dataId,target){
        downloadUrl = downloadUrl.replace(/\\/g,"/");
        let url = deploymentUrl + "/common/download/tdtresource?name="+ downloadUrl;
        window.open(url,"_self");
        let download = deploymentUrl + "/tdtyns/TdtSoftwareResource/getDownCount?id=" + dataId;
        fetch(download, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("下载次数统计失败");
                return;
            }
            console.log("下载次数统计成功");
        });

    }

    // 搜索-------功能---函数---分页===================
    searchClick = (searchIn) => {
        this.cancelSelectAll();
        this.queryParam.keyword = searchIn;
        this.getPageAmount();
    };

    //取消所有选择
    cancelSelectAll(){
        this.setState({["software_type_color_" + this.activeTypeIndex]: this.unselectedClass});
        this.queryParam.softwareType = null;
        this.activeType = false;
        this.activeTypeIndex = 0;

        this.setState({["system_color_" + this.activeCoverageIndex]: this.unselectedClass});
        this.queryParam.softwareSystem = null;
        this.activeCoverage = false;
        this.activeCoverageIndex = 0;

        this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
        this.queryParam.releaseTime = null;
        this.activeYear = false;
        this.activeYearIndex = 0;

        this.queryParam.keyword = null;
    }

    //查询数据---分页
    pagingQuery(activePage, pageSize) {
        let url = deploymentUrl + '/tdtyns/TdtSoftwareResource/list?pageNum='+activePage+'&pageSize='+pageSize+'&delFlag=1&appChannel=tdt_show&siteId=' + siteValue;
        if (this.queryParam.softwareType !== null) {
            url = url + '&softwareType=' + this.queryParam.softwareType;
        }
        if (this.queryParam.softwareSystem !== null) {
            url = url + '&softwareSystem=' + this.queryParam.softwareSystem
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            console.log("data.rows===",data.rows)
            this.setState({resourceList: data.rows});
            this.childSearch.startSearch(false)
        });
    }

    // 查询总页码---加载查询列表
    getPageAmount() {
        let url = deploymentUrl + '/tdtyns/TdtSoftwareResource/getPageTotal?delFlag=1&appChannel=tdt_show&siteId=' + siteValue;
        if (this.queryParam.softwareType !== null) {
            url = url + '&softwareType=' + this.queryParam.softwareType;
        }
        if (this.queryParam.softwareSystem !== null) {
            url = url + '&softwareSystem=' + this.queryParam.softwareSystem
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }

        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            let amountPage = Math.floor((data.data-1) / 10) + 1;
            this.child.setAmountPage(amountPage);
        });
    }

    //查看按钮
    lookOver(item, target) {
        this.hiddenClassifyVessel();
        this.setState({SoftwareResourceClass: this.SoftwareResourceHiddenClass});
        this.setState({popupClass: this.showPopupClass});
        this.setState({popupData: item});
        this.setState({popupTitle: item.softwareTitle});
        this.setState({download_url: item.downloadUrl});

        let pictureStr = item.particularImg;
        if (pictureStr !== "" && pictureStr!==null){
            let pictureStrs = pictureStr.split(/[,| ，]/g);
            this.setState({imgWidth: pictureStrs.length*320 + "px"});
            this.setState({particular_img: pictureStrs});
        }
        this.setState({application_introduced: item.applicationIntroduced});
        this.setState({version: item.softwareVersion});
        this.setState({developer: item.softwareDeveloper});
        this.setState({keywords: item.keyword});

        let url = deploymentUrl + '/tdtyns/TdtSoftwareResource/getViewCount?id=' + item.id;
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查看次数统计失败");
                return;
            }
            console.log("查看次数统计成功");
        });
    }
    //关闭弹窗按钮
    closePopup(target){
        this.showClassifyVessel();
        this.setState({SoftwareResourceClass: this.SoftwareResourceShowClass});
        this.setState({popupClass: this.hiddenPopupClass});
        this.setState({popupData: {}});
        this.setState({popupTitle: ""});
        this.setState({download_url: ""});
        this.setState({imgWidth: 0});
        this.setState({particular_img: []});
        this.setState({application_introduced: ""});
        this.setState({version: ""});
        this.setState({developer: ""});
        this.setState({keywords: ""});
    }

    // 隐藏分类
    hiddenClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyHiddenClass})
    }
    // 显示分类
    showClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyShowClass})
    }
}

export default SoftwareResource
