import {message} from "antd";

/**
 * 错误 -- 提示
 * @constructor
 */
const errorMsg = (content,duration,onClose) =>{
    message.error(content,duration,onClose)
        .then(r => {
            console.log("errorMsg",r)
        })
}
/**
 * 成功 -- 提示
 * @constructor
 */
const successMsg = (content,duration,onClose) =>{
    message.success(content,duration,onClose)
        .then(r => {
            console.log("successMsg",r)
        })
}
/**
 * 提示信息 -- 提示
 * @constructor
 */
const infoMsg = (content,duration,onClose) =>{
    message.info(content,duration,onClose)
        .then(r => {
            console.log("infoMsg",r)
        })
}
/**
 * 警告 -- 提示
 * @constructor
 */
const warningMsg = (content,duration,onClose) =>{
    message.warning(content,duration,onClose)
        .then(r => {
            console.log("warningMsg",r)
        })
}
/**
 * 加载 -- 提示
 * @constructor
 */
const loadingMsg = (content,duration,onClose) =>{
    message.loading(content,duration,onClose)
        .then(r => {
            console.log("loadingMsg",r)
        })
}

export {
    errorMsg,
    successMsg,
    infoMsg,
    warningMsg,
    loadingMsg
}