import React from 'react';
import './RuralRevitalization.css';
import {staticSourceUrl} from "../../../../config/setting";
//数据可视化
class RuralRevitalization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
        this.peoPleWorkData = [];
        this.clickToEnter = {};
        this.title = "";
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/homePage/ruralRevitalization/RuralRevitalization.json', {method: "GET"}).then(res => res.json()).then(data => {
            this.peoPleWorkData = data.items;
            this.clickToEnter = data.clickToEnter;
            this.title = data.title;
            this.setState({loaded: true});
        });
    }

    render() {
        return (
            <div className={'rural-revitalization-panel'}>
                <div className={'rural-revitalization-panel-title'}>
                    <div className={"rural-revitalization-panel-title-tabs"}>
                        <span>{this.title}</span>
                    </div>
                </div>
                <div className={'rural-revitalization-panel-container'}>
                    {
                        this.peoPleWorkData.map((item, index) =>
                            <a href={item.href} target={item.target} key={index}
                               onMouseLeave={this.peopleMouseLeave.bind(this)} onMouseOver={this.peopleMouseOver.bind(this)}>
                                <span>{item.name}</span>
                                <img src={staticSourceUrl + item.imgLogo}/>
                            </a>
                        )
                    }
                </div>
            </div>
        );
    }

    peopleMouseOver(e){
        e.currentTarget.childNodes[0].style.display = "block";
        e.currentTarget.childNodes[1].style.display = "none";
    }
    peopleMouseLeave(e){
        e.currentTarget.childNodes[0].style.display = "none";
        e.currentTarget.childNodes[1].style.display = "block";
    }
}

export default RuralRevitalization;
