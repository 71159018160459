import React from 'react';
import './DeveloperPanel.css';
import Title from '../title/Title';
import {controlUrl, deploymentUrl, publishSite} from "../../../../config/setting";
import {Popconfirm} from "antd";
import {getYNToken, removeYNToken, removeZWToken} from "../../../../modules/CookieTools";
import defined from "../../../../modules/defined";
import $ from "jquery";
import {logoutApi} from "../../../../api/UserApi";

class DeveloperPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      currentApi: null,
      becomeDeveloper: null,
      learnMore: null,
      developerDatas: [],
      openConfirm: false,
      openResConfirm: false,
    };
  }

  componentDidMount() {
    fetch(
      process.env.PUBLIC_URL +
      '/staticData/homePage/developerPanel/DeveloperPanel.json',
      { method: 'GET' }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          title: data.title,
          currentApi: data.items[0],
          becomeDeveloper: data.becomeDeveloper,
          learnMore: data.learnMore,
          developerDatas: data.items
        });
      });
  }

  mebuClick(data) {
    if (data.name == "二三维 API") {
      return;
    }
    else {
      let newData = null;
      this.state.developerDatas.forEach((item) => {
        if (item.name === data.name) {
          newData = item;
        }
      });
      this.setState({ ...this.state, currentApi: newData });
    }
  }

  //返回国家节点，退出云南节点账号
  confirm(e){
    this.setState({openConfirm: false});
    this.exitLoadYNTdt()
    window.open("https://console.tianditu.gov.cn/api/key","_blank")
  };

  //退出天地图云南
  exitLoadYNTdt(){
    logoutApi().then(res =>{
      removeYNToken()
    }).catch(err =>{
      console.log('退出天地图云南失败', err);
    })
  }

  //退出国家天地图。
  exitLoadTianditu() {
    $.ajax({
      url: deploymentUrl + '/auth/tianditu/casLogout?redirectUrl=' + publishSite,
      type: 'GET',
      dataType: 'json',
      async: true,
      success: function (data) {
      },
      error: function (err) {
      }
    });
  }



  //本省节点账号登录弹出提示框，否则直接跳转
  onBackCn(e){
    if (defined(getYNToken())) {
      this.setState({openConfirm: true});
    }else {
      this.setState({openConfirm: false});
      window.open("https://console.tianditu.gov.cn/api/key","_blank")
    }
  };

  //跳转控制台，登录省节点账号
  confirmRes(e){
    this.setState({openResConfirm: false});
    this.exitLoadTianditu()
    window.open(controlUrl,"_blank")
  };


  //本省节点账号登录直接跳转控制台，否则弹出提示
  onToRes(e){
    let that = this;
    //获取国家用户信息---能获取到-国家用户登录；获取不到-未登录
    $.ajax({
      type: 'get',
      async: false,
      url: deploymentUrl + '/auth/tianditu/casInfo',
      success: function (res) {
        if (res.code === 200) {
          that.setState({openResConfirm: true});
        } else {
          that.setState({openResConfirm: false});
          window.open(controlUrl,"_blank")
        }
      },
      error: function (err) {
        that.setState({openResConfirm: false});
        window.open(controlUrl,"_blank")

      }
    });
  };

  render() {
    return (
      <div className={'developer-panel'}>
        <div className="developer-panel-container">
          <div className="developer-paneltitle">
            <Title title={this.state.title} />
          </div>
          <div className="developer-panel-content">
            <div className="developer-panel-content-menu">
              {this.state.developerDatas.map((item, index) => (
                <div
                  key={index}
                  className={` developer-panel-content-menu-item ${this.state.currentApi.name === item.name ? 'active' : ''
                    }`}
                  onClick={this.mebuClick.bind(this, item)}
                >
                  <div className=" developer-panel-content-menu-item-title">
                    {item.name}
                  </div>
                  <img
                    className=" developer-panel-content-menu-item-icon"
                    src={process.env.PUBLIC_URL + item.icon}
                    alt=""
                  />
                  <a href={item.href} target={item.target} className={item.className}>
                  </a>
                </div>
              ))}
              <Popconfirm
                  open={this.state.openConfirm}
                  title="当前账号为省级节点账号，要成为国家天地图节点开发者需登录国家节点账号"
                  onCancel={() =>{ this.setState({openConfirm: false});}}
                  onConfirm={this.confirm.bind(this)}
                  okText="确定"
                  cancelText="取消"
              >
              <a
                className="developer-panel-content-menu-link"
                onClick={this.onBackCn.bind(this)}
              >
                {this.state.becomeDeveloper?.name}
              </a>
              </Popconfirm>
              <Popconfirm
                  open={this.state.openResConfirm}
                  title="当前账号为国家天地图节点账号，如需申请或查看本地资源请使用省级节点账号登录"
                  onCancel={() =>{ this.setState({openResConfirm: false});}}
                  onConfirm={this.confirmRes.bind(this)}
                  okText="确定"
                  cancelText="取消"
              >
              <a
                className="developer-panel-content-menu-link"
                onClick={this.onToRes.bind(this)}
              >
                本地资源申请 →
              </a>
            </Popconfirm>
            </div>
            <div className="developer-panel-content-list">
              {this.state.currentApi?.childs?.map((item, index) => (
                <div key={index} className="developer-panel-content-list-item">
                  <a href={item.href} target={item.target}>
                    <div className="developer-panel-content-list-item-img">
                      <img src={process.env.PUBLIC_URL + item.img} alt="" />
                    </div>
                    <div className="developer-panel-content-list-item-title">
                      {item.name}
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="developer-panel-content-more">
            <a
              href={this.state.learnMore?.href || ''}
              target={this.state.learnMore?.target}
            >
              <button>{this.state.learnMore?.name}</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default DeveloperPanel;
