import React from 'react';
import './SuggestFeedback.css';
import {
    Avatar,
    Button,
    Col,
    ConfigProvider,
    Divider,
    Empty,
    Input,
    Pagination,
    Row,
    Select,
    Space,
    Spin,
    Tabs,
    Tag
} from 'antd';
import {EyeOutlined, LoadingOutlined, MessageOutlined, SearchOutlined, UserOutlined, ClearOutlined} from '@ant-design/icons';
import zh_CN from "antd/lib/locale-provider/zh_CN";
import {Link} from "react-router-dom";
import {deploymentUrl, siteValue} from "../../../../config/setting";

const {TabPane} = Tabs;
const {Option} = Select;
const loadingIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

class SuggestFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // 查询按钮参数
            queryParams: "",
            // 数据总数
            dataTotal: 0,
            // 标签页切换
            tagsChangeKey: 1,
            // 当前页码
            currentPage: 1,
            // 加载状态
            loadingState: false,
            // 登录状态
            loginState: false,
            // 用户名
            userName: "wangzihao",
            // 数据审核状态列表
            stateOptions: [],
            //数据列表
            resultList: [],
        };
    }

    componentDidMount() {
        this.loadReplyData(1, 10);
    }

    //分页查询
    loadReplyData(pageNumber, pageSize) {
        // 请求前加载动画
        this.setState({loadingState: true});
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/list?pageNum=' + pageNumber + '&pageSize=' + pageSize
            + '&deleteFlag=0&byState=true&siteId=' + siteValue, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            this.setState({dataTotal: data.total});
            // 加载动画延迟结束
            setTimeout(() => {
                this.setState({
                    loadingState: false
                });
            }, 500);
            let rows = data.rows;
            if (rows.length === 0) {
                this.createEmptyData();
            } else {
                this.setState({resultList: rows});
            }
        });
    }

    //根据查看量分页查询
    loadReplyHotData(pageNumber, pageSize) {
        // 请求前加载动画
        this.setState({loadingState: true});
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/hotList?pageNum=' + pageNumber + '&pageSize=' + pageSize
            + '&deleteFlag=0&byState=true&siteId=' + siteValue, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            this.setState({dataTotal: data.total});
            // 加载动画延迟结束
            setTimeout(() => {
                this.setState({
                    loadingState: false
                });
            }, 500);
            let rows = data.rows;
            if (rows.length === 0) {
                this.createEmptyData();
            } else {
                this.setState({resultList: rows});
            }
        });
    }

    //根据用户名查询留言列表
    loadPersonalReplyData(pageNumber, pageSize, userName) {
        // 请求前加载动画
        this.setState({loadingState: true});
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/personal/' + userName + '?pageNum=' + pageNumber + '&pageSize=' + pageSize
            + '&deleteFlag=0&siteId=' + siteValue, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            this.setState({dataTotal: data.total});
            // 加载动画延迟结束
            setTimeout(() => {
                this.setState({
                    loadingState: false
                });
            }, 500);
            let rows = data.rows;
            if (rows.length === 0) {
                this.createEmptyData();
            } else {
                this.setState({resultList: rows});
            }
        });
    }

    //空数据显示
    createEmptyData() {
        this.setState({resultList: null});
    }

    limitEmail(value) {
        if (value === null || undefined) {
            return;
        }
        return value.substr(0, 2) + "***" + value.substr(value.indexOf("@") - 2);
    }

    limitName(value){
        if(value !== null || undefined){
            value = value.replace(/\s+/g,"");
            let strTemp = value.substring(0,2);
            for (let i = 1; i < value.length; i++) {
                strTemp += "*"
            }
            return strTemp;
        }
    }

    render() {
        return (
            <div className={"suggestFeedback"}>
                <div className={"suggestFeedback-button"}>
                    <div className={"suggestFeedback-type"}>
                        <Select
                            placeholder="筛选分类"
                            allowClear
                            size='small'
                            style={{width: '100%'}}
                            onChange={this.onSelectChange}>
                            <Option value="地图服务"> 地图服务 </Option>
                            <Option value="开发资源"> 开发资源 </Option>
                            <Option value="专题频道"> 专题频道 </Option>
                            <Option value="应用案例"> 应用案例 </Option>
                            <Option value="地图产品"> 地图产品 </Option>
                            <Option value="资源下载"> 资源下载 </Option>
                            <Option value="其他"> 其他 </Option>
                        </Select>
                    </div>
                    <div className={"suggestFeedback-search"}>
                        <Input
                            name='query'
                            size='small'
                            placeholder="请输入标题关键词"
                            style={{width: '100%'}}
                            onChange={(e) => {
                                this.setState({queryParams: e.target.value})
                            }}
                        />
                    </div>
                    <div className={"suggestFeedback-search-but"}>
                        <Button shape="circle"
                                icon={<SearchOutlined/>}
                                size='small'
                                style={{width: '24px'}}
                                onClick={this.onSearch}
                        />
                    </div>
                    <div className={"suggestFeedback-search-but"}>
                        <Button shape="circle"
                                icon={<ClearOutlined/>}
                                size='small'
                                style={{width: '24px'}}
                                onClick={this.onClear}
                        />
                    </div>
                    <div className={"suggestFeedback-submit"}>
                        <Link to={"/supportInfo/feedback"}>
                            <Button
                                size='small'
                                type="primary">
                                提交建议
                            </Button>
                        </Link>
                    </div>
                </div>
                <Tabs defaultActiveKey="1" onChange={this.onTagChange} size="large">
                    <TabPane tab="最新提交" key="1"/>
                    <TabPane tab="最热评论" key="2"/>
                    {/*<TabPane tab="我的评论" key="3"/>*/}
                </Tabs>
                <div className={"suggestFeedback-content"}>
                    <Spin size="large"
                          tip="Loading..."
                          indicator={loadingIcon}
                          spinning={this.state.loadingState}>
                        <div className={"creation-reply-list"}>
                            {this.state.resultList === null ? (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"暂无数据"}/>
                            ):(
                                this.state.resultList.map((item, key) =>
                                    <div key={key} className={"creation-reply-list-item"}>

                                        <div className={"creation-reply-list-item-title"}>
                                            <div className={"creation-reply-list-item-title-name"}>
                                                <Link to={"/supportInfo/suggestFeedback/" + item.id}>
                                                    {item.creationTitle}
                                                </Link>
                                            </div>
                                            <div className={"creation-reply-list-item-title-name-status"}>
                                                {this.getStateName(item.state)}
                                            </div>
                                        </div>
                                        <Row>
                                            <Col flex="70px">
                                                {item.creationType === null || undefined ? <Tag>暂无分类</Tag> :
                                                    <Tag>{item.creationType}</Tag>}
                                            </Col>
                                            <Col flex="70px">
                                                {<Tag>{item.deviceType}</Tag>}
                                            </Col>
                                        </Row>

                                        <div className={"creation-reply-list-item-problem"}>
                                            {item.creationContent}
                                        </div>
                                        <Row>

                                            <Col flex={"32px"}>
                                                <Avatar style={{
                                                    marginRight: '10px',
                                                    width: '24px',
                                                    height: '24px',
                                                    marginTop: '2px'
                                                }} icon={<UserOutlined/>}/>
                                            </Col>
                                            <Col flex={"150px"}>
                                                <span className={"creation-reply-list-item-title-2"}>{this.limitName(item.creationName)}</span>
                                            </Col>
                                            <Col flex={"auto"}>
                                                <span className={"creation-reply-list-item-title-2"}>
                                                    {
                                                        item.creationTime !== null && item.creationTime !== "" ? "发布于:   " + item.creationTime : ""
                                                    }
                                                </span>
                                            </Col>
                                            <Col flex={"140px"}>
                                                <Space className={"creation-reply-count-icon"}>
                                                    <EyeOutlined/>
                                                </Space>
                                                <span className={"creation-reply-count"}>{item.viewCount}</span>
                                                <Space className={"creation-reply-count-icon"}>
                                                    <MessageOutlined/>
                                                </Space>
                                                <span
                                                    className={"creation-reply-count"}>{item.replyCount}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                        </div>
                        <ConfigProvider locale={zh_CN}>
                            <Pagination total={this.state.dataTotal}
                                        defaultCurrent={1}
                                        size={"small"}
                                        current={this.state.currentPage}
                                        showTotal={(total) => `共 ${total} 条数据`}
                                        onChange={this.onPageChange}
                                        style={{height: '32px', lineHeight: '32px', textAlign: 'center'}}/>
                        </ConfigProvider>
                    </Spin>
                </div>
            </div>
        );
    }

    //页码改变
    onPageChange = (page, pageSize) => {
        this.setState({currentPage: page});
        switch (this.state.tagsChangeKey) {
            case 1:
                this.loadReplyData(page, pageSize);
                break;
            case 2:
                this.loadReplyHotData(page, pageSize);
                break;
            case 3:
                this.loadPersonalReplyData(page, pageSize, this.state.userName)
                break;
        }
    }

    //标签切换
    onTagChange = (key) => {
        //标签切换后默认从第一页开始
        this.setState({currentPage: 1})
        switch (key) {
            case "1":
                this.setState({tagsChangeKey: 1});
                this.loadReplyData(1, 10);
                break;
            case "2":
                this.setState({tagsChangeKey: 2});
                this.loadReplyHotData(1, 10);
                break;
            case "3":
                //判断是否用户登录
                if (this.state.loginState) {
                    //若已经登录则显示该用户所有留言
                    this.setState({tagsChangeKey: 3});
                    this.loadPersonalReplyData(1, 10, this.state.userName);
                } else {
                    //若还未登录则跳转登录界面
                    window.location.href = "/index";
                }
                break;
        }
    };

    //查询按钮
    onSearch = () => {
        // 请求前加载动画
        this.setState({loadingState: true});
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/list?pageNum=' + 1 + '&pageSize=' + 10
            + '&deleteFlag=0&byState=true&siteId=' + siteValue
            + '&creationTitle=' + this.state.queryParams, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            // 请求后关闭加载动画
            this.setState({loadingState: false});
            this.setState({dataTotal: data.total});
            let rows = data.rows;
            if (rows.length === 0) {
                this.createEmptyData();
            } else {
                this.setState({resultList: rows});
            }
        });
    }

    onClear = () =>{
        // 请求前加载动画
        this.setState({loadingState: true});
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/list?pageNum=' + 1 + '&pageSize=' + 10
            + '&deleteFlag=0&byState=true&siteId=' + siteValue, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("清空失败");
                return;
            }
            // 请求后关闭加载动画
            this.setState({loadingState: false});
            this.setState({dataTotal: data.total});
            let rows = data.rows;
            if (rows.length === 0) {
                this.createEmptyData();
            } else {
                this.setState({resultList: rows});
            }
        });
    }

    //筛选按钮
    onSelectChange = (value) => {
        if (value === undefined) {
            //清空时查询全部
            this.loadReplyData(1, 10);
        } else {
            fetch(deploymentUrl + '/tdtyns/TdtGbookNew/list?pageNum=' + 1 + '&pageSize=' + 10
                + '&deleteFlag=0&byState=true&siteId=' + siteValue
                + '&creationType=' + value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then(data => {
                if (data.code !== 200) {
                    alert("查询留言失败");
                    return;
                }
                this.setState({dataTotal: data.total});
                let rows = data.rows;
                if (rows.length === 0) {
                    this.createEmptyData();
                } else {
                    this.setState({resultList: rows});
                }
            });
        }
    }

    //标签状态数据显示
    getStateName(state) {
        switch (state) {
            case "0":
                return (<Tag color={"blue"}>{"正在审核"}</Tag>);
            case "1":
                return (<Tag color={"green"}>{"审核通过"}</Tag>);
            case "2":
                return (<Tag color={"cyan"}>{"已采纳"}</Tag>);
            case "3":
                return (<Tag color={"red"}>{"未通过审核"}</Tag>);
        }
    }

}

export default SuggestFeedback;
