import React from "react";
import './News.css';
import {Button, Carousel, ConfigProvider, Divider, Empty, Input, List, Pagination, Space, Spin, Tabs} from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import {Link, Route, Switch} from "react-router-dom";
import {deploymentUrl, siteValue, staticSourceUrl} from "../../../../config/setting";

const { Search } = Input;
const { TabPane } = Tabs;
const loadingIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

class News extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // 新闻公告数据
            newsData: [],
            // 数据总数
            dataTotal: 0,
            // 当前页码
            currentPage: 1,
            // 标签页切换
            tagsChangeKey: 1,
            // 加载状态
            loadingState: false
        }
    }

    componentDidMount(){
        // 查询新闻公告列表（默认查询全部）
        this.queryNews(1,10,'');
    }

    render() {
        return (
            <div className={"news"}>
                <Search placeholder="请输入关键词" onSearch={this.onSearchNews} enterButton style={{marginTop: 10, marginLeft: 700, width: 280}}/>
                <Tabs defaultActiveKey="1" size={"large"} onChange={this.onTabsChange}>
                    <TabPane tab="全部" key="1">
                    </TabPane>
                    {/*<TabPane tab="业界资讯" key="2">
                    </TabPane>
                    <TabPane tab="通知公告" key="3">
                    </TabPane>*/}
                </Tabs>
                <div>
                    <Spin size="large"
                          tip="Loading..."
                          indicator={loadingIcon}
                          spinning={this.state.loadingState}>
                        <List
                            size="large"
                            dataSource={this.state.newsData}
                            renderItem={(item) =>
                                <List.Item className={"news-list-item"}>
                                    <Space size={10}>
                                        <p className={"news-list-item-date"}>
                                            {item.createTime.substring(5,10)}
                                            <br/>
                                            <span>
                                        {item.createTime.substring(0,4)}
                                    </span>
                                        </p>
                                        <Divider type="vertical" style={{height: 50, borderColor: "#d9d9d9"}}/>
                                        <div>
                                            {this.newsLinkTo(item)}
                                            {item.thumbnail !== null || undefined ? (
                                                <img style={{width: 90, position: "absolute", right: 10}} src={staticSourceUrl + item.thumbnail}/>
                                            ):(
                                                <img style={{width: 90, position: "absolute", right: 10}} src={"https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}/>
                                            )}
                                        </div>
                                    </Space>
                                </List.Item>
                            }
                        />
                    </Spin>
                </div>
                <ConfigProvider locale={zh_CN}>
                    <Pagination total={this.state.dataTotal}
                                defaultCurrent={1}
                                current={this.state.currentPage}
                                onChange={this.onPageChange}
                                showTotal={(total) => `共 ${total} 条数据`}
                                style={{height: '32px', lineHeight: '32px', textAlign: 'center'}}/>
                </ConfigProvider>
            </div>
        );
    }

    // 新闻公告跳转
    newsLinkTo(item){
        if(item.newsLink !== null || undefined){
            if(item.linkType === '_self'){
                return(
                    <a target="_self"
                       href={item.newsLink}>
                       <span className={"news-list-item-title"}>
                          {item.title}
                       </span>
                    </a>
                );
            }
            if(item.linkType === '_blank'){
                return(
                    <a target="_blank"
                       href={item.newsLink}>
                        <span className={"news-list-item-title"}>
                            {item.title}
                        </span>
                    </a>
                );
            }
        }
        else {
            return(
                <Link to={'/supportInfo/news/' + item.id}>
                     <span className={"news-list-item-title"}>
                      {item.title}
                   </span>
                </Link>
            );
        }
    }

    // 查询新闻公告列表
    queryNews(page, pageSize, newsType){
        // 请求前加载动画
        this.setState({loadingState: true});
        // 获取全部新闻公告
        fetch(deploymentUrl + '/tdtyns/TdtNewsNew/list?pageNum=' + page + '&pageSize=' + pageSize
            + '&deleteFlag=1&siteId=' + siteValue
            + '&newsType=' + newsType, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            }
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询新闻公告失败");
                return;
            }
            // 加载动画延迟结束
            setTimeout(() => {
                this.setState({
                    loadingState: false
                });
            }, 500);
            this.setState({
                dataTotal:data.total,
                newsData:data.rows
            });
        });
    }

    // 页码切换
    onPageChange = (page, pageSize) => {
        this.setState({currentPage: page});
        switch (this.state.tagsChangeKey) {
            case 1:
                this.queryNews(page, pageSize, "");
                break;
            case 2:
                this.queryNews(page, pageSize, "业界咨询");
                break;
            case 3:
                this.queryNews(page, pageSize, "通知公告");
                break;
        }
    }

    // 标签页切换
    onTabsChange = (key) => {
        // 切换标签后页码重置为1
        this.setState({currentPage: 1})
        switch (key) {
            case "1":
                this.setState({tagsChangeKey: 1});
                this.queryNews(1,10,"");
                break;
            case "2":
                this.setState({tagsChangeKey: 2});
                this.queryNews(1,10,"业界咨询");
                break;
            case "3":
                this.setState({tagsChangeKey: 3});
                this.queryNews(1,10,"通知公告");
                break;
        }
    };

    // 新闻搜索框
    onSearchNews = (value) =>{
        // 请求前加载动画
        this.setState({loadingState: true});
        // 获取全部新闻公告
        fetch(deploymentUrl + '/tdtyns/TdtNewsNew/list?pageNum=' + 1 + '&pageSize=' + 10
            + '&deleteFlag=1&siteId=' + siteValue
            + '&title=' + value, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            }
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询新闻公告失败");
                return;
            }
            // 加载动画延迟结束
            setTimeout(() => {
                this.setState({
                    loadingState: false
                });
            }, 500);
            this.setState({
                dataTotal: data.total,
                newsData: data.rows
            });
        });
    }

}

export default News;
