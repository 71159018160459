import React from 'react';
import './HeaderNavigation.css';
import { Link } from 'react-router-dom';
import {deploymentUrl, siteValue} from "../../config/setting";

class HeaderNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TdtChannels: [],
      activePage: '/home',
      isShowMenu: false,
      showOpenMenu: ''
    };
  }

  componentDidMount() {
    this.getNavItemData();
  }

  getNavItemData() {
    let url =
      deploymentUrl +
      '/tdtyns/TdtChannel/list?delFlag=1&siteId=' +
      siteValue;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      mode: 'cors',
      cache: 'default'
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code !== 200) {
          alert('查询数据失败');
          return;
        }
        let rows = data.data;
        let TdtChannels = [];
        for (let row of rows) {
          if (row.parentId === 0) {
            let temp = {
              name: row.channelName,
              target: row.channelTarget,
              href: row.channelHref,
              second: []
            };
            for (let item of rows) {
              if (row.id === item.parentId) {
                let secondTemp = {
                  name: item.channelName,
                  target: item.channelTarget,
                  href: item.channelHref,
                  second: []
                };
                temp.second.push(secondTemp);
              }
            }
            TdtChannels.push(temp);
          }
        }
        this.setState({
          TdtChannels: TdtChannels,
          activePage: this.state.activePage
        });
      });
  }

  switchPage(href) {
    this.setState({
      TdtChannels: this.state.TdtChannels,
      activePage: href
    });
  }

  render() {
    return (
      <div className={'headerNavigation'}>
        <div
          className={`headerNavigationCon ${
            this.state.isShowMenu ? 'active' : ''
          }`}
        >
          {this.state.TdtChannels.map((site, key) =>
            site.target === '_self' || site.target === '_blank' ? (
              <div key={key} className={'headerNavigationCon-primary'}>
                {site.target === '_self' ? (
                  <Link
                    style={{ borderLeft: key === 0 ? 'none' : false }}
                    className={`headerNavigationCon-title ${
                      this.state.activePage === site.href ? 'active' : ''
                    }`}
                    to={site.href}
                    onClick={() => {
                      this.switchPage(site.href);
                    }}
                  >
                    <span>{site.name}</span>
                  </Link>
                ) : (
                  <a
                    style={{ borderLeft: key === 0 ? 'none' : false }}
                    className={'headerNavigationCon-title'}
                    href={site.href}
                    target={site.target}
                  >
                    <span>{site.name}</span>
                  </a>
                )}
              </div>
            ) : (
              <div
                key={key}
                className={
                  'headerNavigationCon-primary headerNavigationCon-primary-hasChild'
                }
              >
                <div
                  className="headerNavigationCon-title"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showOpenMenu:
                        this.state.showOpenMenu === site.name ? '' : site.name
                    });
                  }}
                >
                  <span style={{ borderLeft: key === 0 ? 'none' : false }}>
                    {site.name}
                  </span>
                  <span className="headerNavigationCon-title-icon">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/staticImg/homePage/icon/down_arrow.png'
                      }
                      alt=""
                    />
                  </span>
                </div>
                <div
                  className={`headerNavigationCon-secondary-con ${
                    this.state.showOpenMenu === site.name ? 'open' : ''
                  }`}
                >
                  {site.second.map((second, index) => (
                    <div
                      key={index}
                      className={'headerNavigationCon-secondary'}
                    >
                      {second.target === '_self' ? (
                        <Link
                          className={'headerNavigationCon-secondary-title'}
                          to={second.href}
                        >
                          <span>{second.name}</span>
                        </Link>
                      ) : (
                        <a
                          className={'headerNavigationCon-secondary-title'}
                          href={second.href}
                          target={second.target}
                        >
                          <span>{second.name}</span>
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
        <div
          className="headerNavigationCon-menu"
          onClick={() => {
            this.setState({
              ...this.state,
              isShowMenu: !this.state.isShowMenu
            });
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL + '/staticImg/homePage/icon/menu_icon.png'
            }
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default HeaderNavigation;
