import React from 'react';
import './Applications.css';
import Footer from '../../../components/footer/Footer';
import {deploymentUrl, siteValue, staticSourceUrl} from "../../../config/setting";


class Applications extends React.Component {
    constructor(props) {
        super(props);
        this.ApplicationsClassifyShowClass = "Applications-list-classify-show";
        this.ApplicationsClassifyHiddenClass = "Applications-list-classify-hidden";

        this.applicationsListShowClass = "Applications-content-list-show";
        this.applicationsListHiddenClass = "Applications-content-list-hidden";

        this.applicationsPopupShowClass = "Applications-content-popup-show";
        this.applicationsPopupHiddenClass = "Applications-content-popup-hidden";
        this.state = {
            ApplicationsClassifyClass: this.ApplicationsClassifyShowClass,
            applicationsListClass: this.applicationsListShowClass,
            applicationsPopupClass: this.applicationsPopupHiddenClass,
            classifies: [],
            labels: [],
            applicationData: [],
            effectImg: [],
            applicationInfo: {}
        };
        this.typeName = "";
        this.labelIndex = 0;
        this.labelSelectIndex = -1;
        this.where = {
            parentTypeId:null,
            parentLabelId:null
        }
    }
    componentDidMount(){
        //应用案例分类
        fetch(deploymentUrl + '/tdtyns/TdtApplicationType/list?delFlag=1&siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({classifies: data.rows});
            if (data.rows.length > 0) {
                this.classifyClick(0,data.rows[0].id,data.rows[0].typeName,undefined);
            }
        });
    }
    render() {
        return (
            <div className={'application-container'}>
                <div className={'Applications-list-container'}>
                    <div className={this.state.ApplicationsClassifyClass}>
                        <div className={"Applications-list-classify-vessel"}>
                            <div className={"Applications-list-classify-title"}>
                                {
                                    this.state.classifies.map((item,key)=>
                                        <div onClick={this.classifyClick.bind(this,key,item.id,item.typeName)}
                                             className={this.state["classify_" + key]===undefined?"Applications-list-classify-title-un":this.state["classify_" + key]} key={key}>{item.typeName}</div>
                                    )
                                }
                            </div>
                            <div className={"Applications-list-labels-con"}>
                                {
                                    this.state.labels.map((item, key) =>
                                        <div onClick={this.labelClick.bind(this,key,item.id)}
                                            className={this.state["label_" + key]===undefined?"Applications-list-labels-con-un":this.state["label_" + key]} style={{color:item.labelColor,border: "1px solid " + item.labelColor}} key={key}>
                                            {item.labelName}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"Applications-content"}>
                        <div className={'Applications-content-list'}>
                            <div className={'Applications-content-list-title'}>
                                {"应用案例 > " + this.typeName}
                            </div>
                            <div className={this.state.applicationsListClass}>
                                {
                                    this.state.applicationData.map((item,key)=>
                                        <div className={"Applications-content-list-item"} key={key}>
                                            <div className={"Applications-content-list-item-title"}>
                                                <span title={item.applyName}>{item.applyName}</span>
                                                <div onClick={this.applicationsInfoClick.bind(this,item)}>{"详情>>"}</div>
                                            </div>
                                            <div className={"Applications-content-list-item-img"}>
                                                <img onClick={this.applicationsInfoClick.bind(this,item)} src={staticSourceUrl + item.titleImg}/>
                                            </div>
                                            <div className={"Applications-content-list-item-des"}>
                                                <p>
                                                    <strong>{"更新日期："}</strong>
                                                    {item.applicationUpdataTime}
                                                </p>
                                                <div className={"Applications-content-list-item-des-label"}>
                                                    <strong>{"标签分类："}</strong>
                                                    {
                                                        this.state.labels.map((itemT, key) =>
                                                            parseInt(item.parentLabelId) === itemT.id ?
                                                                <div style={{color:itemT.labelColor,border: "1px solid " + itemT.labelColor}} key={key}>
                                                                    {itemT.labelName}
                                                                </div>: false
                                                        )
                                                    }
                                                </div>
                                                <p>
                                                    <strong>{"摘要："}</strong>
                                                    {item.appAbstract}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {/*弹窗*/}
                            <div className={this.state.applicationsPopupClass}>
                                <div className={"Applications-popup-title"}>
                                    <div>{this.state.applicationInfo.applyName}</div>
                                    <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                                </div>
                                <div className={"Applications-popup-Vessel"}>
                                    {
                                        this.state.applicationInfo.appTarget === "_blank"?<div className={"Applications-popup-Vessel-but"}>
                                            <a href={this.state.applicationInfo.appUrl} target={this.state.applicationInfo.appTarget}>{"进入系统>>"}</a>
                                        </div>:false
                                    }
                                    <div className={"Applications-popup-Vessel-des"}>
                                        <p><strong>{"应用部门:"}</strong>{this.state.applicationInfo.departmentApplied}</p>
                                        <p><strong>{"应用领域:"}</strong>{this.state.applicationInfo.departmentTerritory}</p>
                                        <p><strong>{"技术联系人:"}</strong>{this.state.applicationInfo.appContacts}</p>
                                        <p><strong>{"联系电话:"}</strong>{this.state.applicationInfo.contactNumber}</p>
                                        <p><strong>{"电子邮箱:"}</strong>{this.state.applicationInfo.appEmail}</p>
                                        <p><strong>{"联系单位:"}</strong>{this.state.applicationInfo.contactUnit}</p>
                                        <p><strong>{"联系地址:"}</strong>{this.state.applicationInfo.contactAddress}</p>
                                        <p><strong>{"简要介绍:"}</strong>{this.state.applicationInfo.appIntroduction}</p>
                                    </div>
                                    <div className={"Applications-popup-Vessel-img"}>
                                        <p>
                                            {"效果图"}
                                        </p>
                                        <div>
                                            {
                                                this.state.effectImg.map((item, index) =>
                                                    <img src={staticSourceUrl + item}/>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Footer version={0}/>
                    </div>
                </div>
            </div>
        );
    }
    //详情
    applicationsInfoClick(item, target){
        this.setState({ApplicationsClassifyClass: this.ApplicationsClassifyHiddenClass});
        this.setState({applicationsListClass: this.applicationsListHiddenClass});
        this.setState({applicationsPopupClass: this.applicationsPopupShowClass});
        this.setState({applicationInfo:item});
        let effectImgStr = item.effectImg;
        if (effectImgStr !== "" && effectImgStr !== null) {
            let effectImgStrs = effectImgStr.split(/[,| ，]/g);
            this.setState({effectImg:effectImgStrs});
        }
    }
    //关闭弹窗
    closePopup(){
        this.setState({ApplicationsClassifyClass: this.ApplicationsClassifyShowClass});
        this.setState({applicationsListClass: this.applicationsListShowClass});
        this.setState({applicationsPopupClass: this.applicationsPopupHiddenClass});

        this.setState({applicationsListClass: this.applicationsListShowClass});
        this.setState({applicationsPopupClass: this.applicationsPopupHiddenClass});
        this.setState({applicationInfo:{}});
        this.setState({effectImg:[]});
    }
//    分类
    classifyClick(index,id,typeName,target){
        this.typeName = typeName;
        this.setState({["label_" + this.labelIndex]:"Applications-list-labels-con-un"});//改变标签
        this.setState({["classify_" + this.classifyIndex]:"Applications-list-classify-title-un"});
        this.setState({["classify_" + index]:"Applications-list-classify-title-has"});
        this.classifyIndex = index;

        //获取标签数据
        fetch(deploymentUrl + '/tdtyns/TdtApplicationLabel/list?delFlag=1&siteId=' + siteValue + '&typeId=' + id, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({labels:data.rows});
        });

        this.where = {
            parentTypeId:null,
            parentLabelId:null
        };//重置
        this.where.parentTypeId = id;
        this.getApplicationData();
      }
    labelClick(index,id,target){
        this.setState({["label_" + this.labelIndex]:"Applications-list-labels-con-un"});
        if (this.labelSelectIndex === index) {
            this.where.parentLabelId = null;
            this.labelSelectIndex = -1;
        }else {
            this.setState({["label_" + index]:"Applications-list-labels-con-has"});
            this.labelIndex = index;
            this.labelSelectIndex = index;
            this.where.parentLabelId = id;
        }
        this.getApplicationData();

        this.setState({applicationsListClass: this.applicationsListShowClass});
        this.setState({applicationsPopupClass: this.applicationsPopupHiddenClass});
    }
    //根据条件查询数据
    getApplicationData(){
        let url = deploymentUrl + '/tdtyns/TdtApplication/list?delFlag=1&siteId=' + siteValue;
        if (this.where.parentTypeId !== null) {
            url = url + '&parentTypeId=' + this.where.parentTypeId;
        }
        if (this.where.parentLabelId !== null) {
            url = url + '&parentLabelId=' + this.where.parentLabelId;
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            this.setState({applicationData:data.rows});
        });
    }
}
export default Applications;
