
module.exports = {
    /**
     * 请求类型
     **/
    CONTENT_TYPE: "Content-Type",
    /**
     * 请求类型
     **/
    REFERER: "Referer",
    /**
     * 应用Key ---------- 开发者应用
     */
    C_APP_KEY: "Tdt-C-App-Key",
    /**
     * 开发者id ---------- 天地图API对应的系统ID
     */
    C_APP_ID: "Tdt-C-App-Id",
    /**
     * 用户key ---------- 开发者用户
     **/
    C_TENANCY_ID: "Tdt-C-Tenancy-Id",
    /**
     * token传参名称 ---------- 用户登录生成
     */
    TOKEN_KEY: "Tdt-S-Token",
    /**
     * 子系统ID ---------- 调用子系统
     */
    S_APP_ID: "Tdt-S-App-Id",
    /**
     * 子系统Key ---------- 调用子系统(由调用域名或移动端包名生成)
     */
    S_APP_KEY: "Tdt-S-App-Key"
}