import axios from 'axios'
// import store from '@/store'
import {content_type, deploymentUrl, gatewayUrl, loginUrl, s_app_id,} from '../config/setting'
import {CONTENT_TYPE, TOKEN_KEY} from "../config/constant";
import {getYNToken, removeYNToken} from "../modules/CookieTools";
import errorCode from "../config/errorCode";
import {errorMsg} from "../modules/Message";
import {confirmDialog} from "../modules/Dialog";

axios.defaults.headers[CONTENT_TYPE] = content_type
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: gatewayUrl,
  // 超时
  timeout: 10000
})

/**  请求拦截器  **/
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getYNToken() && !isToken) {
    config.headers[TOKEN_KEY] = getYNToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
    errorMsg("请求拦截失败")
    Promise.reject();
})

/**  响应拦截器  **/
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 200) {
        return res.data
    } else if (code === 500) {
        errorMsg(msg)
        return Promise.reject(msg)
    }else if (code === 401) {
        confirmDialog(
            "系统提示",
            "登录状态已过期，请重新登录",
            false,
            "重新登录",
            ()=>{
                console.log("重新登录")
                removeYNToken()
                window.open(loginUrl +"/login?backUrl=" + deploymentUrl + "/home&s_app_id=" + s_app_id, "_self");
            },
            "取消",
            ()=>{
                console.log("取消")
            }
        )
    }else {
        errorMsg(msg)
        return Promise.reject(msg)
    }
  },
  error => {
    let message = error.message;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    errorMsg(message)
    return Promise.reject(error)
  }
)

export default service
