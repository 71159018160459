import request from "./request";
import {S_APP_ID} from "../config/constant";
import {s_app_id} from "../config/setting";


/**
 * @description 天地图云南用户信息获取
 * @author wb
 * @date 2024/8/12 10:08
 * @param sAppId
 */
export function getInfoApi(){
    return request({
        url: '/tdtYnLogin/info',
        method: 'get',
        headers: {[S_APP_ID]: s_app_id},
    })
}

/**
 *退出登录
 * @returns {Promise<AxiosResponse<any>>}
 */
export function logoutApi(){
    return request({
        url: '/tdtYnLogin/logout',
        method: 'get',
        headers: {[S_APP_ID]: s_app_id},
    })
}
