import React from 'react';
import './AppPanel.css';
import {deploymentUrl, siteValue, staticSourceUrl} from "../../../../config/setting";
class ApplicationCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialChannel: [],
        };
    }
    componentDidMount() {
        let url = deploymentUrl + '/tdtyns/TdtApplication/list?delFlag=1&siteId=' + siteValue + '&isTypical=1';
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            this.setState({specialChannel: data.rows})
        });
    }
    render() {
        return (
            <div className={'app-panel-applicationCase'}>
                <div className={'app-panel-specialChannel-title'}>
                    <div className={"app-panel-specialChannel-title-tabs"}>
                        <span>{"典型应用"}</span>
                    </div>
                </div>
                <div className={'app-panel-applicationCase-container'}>
                    {
                        this.state.specialChannel.map((item, index) =>
                            <a href={item.appUrl} target={item.appTarget} key={index}>
                                <img src={staticSourceUrl + item.typicalLogoImg}/>
                                <div className={"app-panel-applicationCase-container-con"}>
                                    <h4 title={item.applyName}>{item.applyName}</h4>
                                    <div className={"app-panel-applicationCase-container-con-des"}>
                                        <span>{"点击查看详情"}</span>
                                        <i className="iconfont applicationCase">&#xe65d;</i>
                                    </div>
                                </div>
                            </a>
                        )
                    }
                </div>
            </div>
        );
    }
}
export default ApplicationCase;
