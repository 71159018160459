
/**
 * 全局设置信息
 */
// -------------------  数据请求 地址 ---------------
const devGatewayUrl = "http://localhost:8081"
const testGatewayUrl = "https://ynsso.ynmap.cn/yntdt"
const proGatewayUrl = "https://sso.ynmap.cn/yntdt"

// -------------------  本地登录平台 地址 ---------------
const devLoginUrl = "http://localhost:5001/authen"
const testLoginUrl = "https://ynsso.ynmap.cn/authen"
const proLoginUrl = "https://yunnan.tianditu.gov.cn/authen"

// -------------------  国家登录平台 地址 ---------------
const devGovUrl = "https://sso.tianditu.cn/"
const testGovUrl = "https://sso.tianditu.cn/"
const proGovUrl = "https://sso.tianditu.gov.cn/"

// -------------------  应用部署 地址  --------------
const devDeploymentUrl = "http://localhost:8088/yntdt"
const testDeploymentUrl = "https://ynsso.ynmap.cn/yntdt"
const proDeploymentUrl = "https://sso.ynmap.cn/yntdt"

// -------------------  静态资源 地址  --------------
const devStaticSourceUrl = "E:/staticSource/yntdt_static"
const testStaticSourceUrl = "https://ynsso.ynmap.cn/staticSource"
const proStaticSourceUrl = "https://sso.ynmap.cn/staticSource"

// -------------------  发布地址  --------------
const devPublishSiteUrl = ""
const testPublishSiteUrl = "https://ynsso.ynmap.cn/yunnan"
const proPublishSiteUrl = "https://yunnan.tianditu.gov.cn"

// -------------------  控制台地址  --------------
const devControlUrl = ""
const testControlUrl = "https://ynsso.ynmap.cn/control"
const proControlUrl = "https://yunnan.tianditu.gov.cn/control"


/**
 * 开发模式
 * dev：开发；test：测试； pro：正式部署
 */
const developSchema = "pro";

module.exports = {
    /**
     * 开发模式
     * dev：开发；test：测试； pro：正式部署
     */
    developSchema: developSchema,

    /**
     * 登录平台地址---网关
     */
    loginUrl: developSchema === "dev" ? devLoginUrl : developSchema === "test" ? testLoginUrl : proLoginUrl,


    /**
     * 国家天地图地址---网关
     */
    govUrl: developSchema === "dev" ? devGovUrl : developSchema === "test" ? testGovUrl : proGovUrl,

    /**
     * 数据请求地址---网关
     */
    gatewayUrl: developSchema === "dev" ? devGatewayUrl : developSchema === "test" ? testGatewayUrl : proGatewayUrl,

    /**
     * 部署地址---网关
     */
    deploymentUrl: developSchema === "dev" ? devDeploymentUrl : developSchema === "test" ? testDeploymentUrl : proDeploymentUrl,

    /**
     * 静态资源地址
     */
    staticSourceUrl: developSchema === "dev" ? devStaticSourceUrl : developSchema === "test" ? testStaticSourceUrl : proStaticSourceUrl,


    /**
     * 发布地址
     */
    publishSite: developSchema === "dev" ? devPublishSiteUrl : developSchema === "test" ? testPublishSiteUrl : proPublishSiteUrl,

    /**
     * 发布地址
     */
    controlUrl: developSchema === "dev" ? devControlUrl : developSchema === "test" ? testControlUrl : proControlUrl,

    /**
     * 国家天地图---登录地址
     */
    tiandituLoginUrl: "https://sso.tianditu.gov.cn/login",



    /**
     * 请求类型
     */
    content_type: "application/json;charset=utf-8",

    /**
     * 子系统ID- 调用子系统---本地
     */
    s_app_id: "c1af3fe6-b9ef-4270-a02b-32403280f103",


    siteValue: "530000000000",
    indexMapCoordinate: [102.70899531385986, 25.047526053605647],//首页地图定位点
    onlineMapUrl: "https://yunnan.tianditu.gov.cn/onlineMap",//在线地图地址
    tdtKey: "b0ad70dc306d789204ddb4ec0b7c2b4d",//在线地图key--国家
    yntdtKey: "c3fcc38488a047cab6d4b55c21c76cbb",//在线地图key--云南
    sAppId: "d5672f80-d850-48a5-8d2a-87420c04b9d9",//子系统ID--云南

}
