import React from 'react';
import './WaterDistribution.css';
import Footer from "../../../../footer/Footer";
import {staticSourceUrl} from "../../../../../config/setting";

class WaterDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.leftIcon = '<i class="iconfont">&#xeb8e;</i>';
        this.rightIcon = '<i class="iconfont">&#xeb8f;</i>';
        this.cutPage = 'waterDistribution-content-paging-cutPage';
        this.forbidPage = "waterDistribution-content-paging-cutPage-forbid";

        this.state = {
            activeImg:"",
            amountPage:0,
            upDisabled: true,
            downDisabled:false,
            upPage:this.forbidPage,
            downPage: this.cutPage,
            nowPage:0,
            isPaging:"mineralDistribution-paging-shell",
        };
        this.param = {
            currentX: 0,
            currentY: 0,
            left: 0,
            top: 0,
            zoomVal:1
        };
        this.imgData = [];
        this.isMouseDown = false;
    }

    componentDidMount(){
        fetch(process.env.PUBLIC_URL + '/staticData/homePage/peoPleWork/WaterDistributionData.json', {method: "GET"}).then(res => res.json()).then(result => {
            this.setState({activeImg: result.data[0].img,amountPage: result.data.length,nowPage:1})
            this.imgData = result.data;
            if (result.data.length === 1){
                this.setState({isPaging: 'mineralDistribution-paging-shell-hidden'})
            }
        });
    }
    render() {
        return (
            <div className={'waterDistribution-container'}>
                <div className={'waterDistribution-list-container'}>
                    <div className={"waterDistribution-content"}>
                        <div className={'waterDistribution-content-list'}>
                            <div className={'waterDistribution-content-list-title'}>
                                {"云南水资源分布 > "}
                            </div>
                            <div className={'waterDistribution-content-shell'}>
                                <div onContextMenu = {(e)=> {e.preventDefault();return false;}} className={'waterDistribution-img-shell'}>
                                    <img style={{top:this.param.top,left:this.param.left,transform:"scale(" + this.param.zoomVal + ")"}}
                                         onMouseOver={this.imageMouseOver.bind(this)} src={staticSourceUrl + this.state.activeImg}/>
                                </div>
                                <div className={this.state.isPaging}>
                                    <div className={'waterDistribution-content-paging'}>
                                        <div className={"waterDistribution-content-paging-amount"}>
                                            {"共 " + this.state.amountPage + " 张"}
                                        </div>
                                        <button disabled={this.state.upDisabled} title={"上一张"} onClick={this.upPageBut.bind(this)} className={this.state.upPage}
                                                dangerouslySetInnerHTML={{__html:this.leftIcon}}
                                        >
                                        </button>
                                        <div className={'waterDistribution-content-paging-nowPage'}>
                                            {this.state.nowPage}
                                        </div>
                                        <button disabled={this.state.downDisabled} title={"下一张"} onClick={this.downPagCut.bind(this)} className={this.state.downPage}
                                                dangerouslySetInnerHTML={{__html:this.rightIcon}}
                                        >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer version={0}/>
                    </div>
                </div>
            </div>
        );
    }
    upPageBut(){
        if (this.state.nowPage > 1){
            this.setNowPageNumber(this.state.nowPage - 1);
        }
    }
    downPagCut(){
        if (this.state.nowPage < this.imgData.length){
            this.setNowPageNumber(this.state.nowPage + 1);
        }

    }
    /**  设置当前页  */
    setNowPageNumber(page){
        if (page <= 1){
            this.setState({upPage: this.forbidPage,upDisabled: true,downPage: this.cutPage,downDisabled:false})
        }else if (page >= this.imgData.length){
            this.setState({upPage: this.cutPage,upDisabled: false,downPage: this.forbidPage,downDisabled:true})
        }else {
            this.setState({upPage: this.cutPage,upDisabled: false,downPage: this.cutPage,downDisabled:false})
        }
        this.setState({nowPage: page,activeImg: this.imgData[page-1].img})
    }
    //图片拖动，放大缩小
    imageMouseOver(target){
        let that = this;
        let element = target.currentTarget;

        //放大缩小
        element.addEventListener("mousewheel",function (e) {
            e.preventDefault();
            e.wheelDelta > 0 ? element.style.cursor = "zoom-in" : element.style.cursor = "zoom-out";
            that.param.zoomVal = (that.param.zoomVal + e.wheelDelta / 1200) < 1 ? 1 : that.param.zoomVal + e.wheelDelta / 1200;
            element.style.transform = "scale(" + that.param.zoomVal + ")";
        });
        element.addEventListener("mousedown",function (e) {
            e.preventDefault();
            that.isMouseDown = true;
            that.param.currentX = e.clientX;
            that.param.currentY = e.clientY;
        });
        element.addEventListener("mouseup",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });

        element.addEventListener("mouseleave",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });
        element.addEventListener("mousemove",function (e) {
            element.style.cursor = "pointer";
            e.preventDefault();
            targetMove(e);
        });

        //图片移动
        function targetMove(e) {
            if (!that.isMouseDown) {
                return;
            }
            let nowX = e.clientX,
                nowY = e.clientY;
            let disX = nowX - that.param.currentX,
                disY = nowY - that.param.currentY;
            element.style.left = parseInt(that.param.left) + disX + "px";
            element.style.top = parseInt(that.param.top) + disY + "px";
        }

        //设定图片拖动后的位置
        function setImgLocation(){
            that.param.top = element.style.top.substring(0, element.style.top.length - 2);
            that.param.left = element.style.left.substring(0, element.style.left.length - 2);
        }
    }
}
export default WaterDistribution;
