import React from 'react';
import Search from '../../common/search/Search';
import PagingButton from '../../common/paging/PagingButton';
import './ServicesList.css';
import Footer from "../../footer/Footer";
import {Popconfirm, Tag} from "antd";
import defined from "../../../modules/defined";
import {controlUrl, deploymentUrl, publishSite, siteValue, staticSourceUrl} from "../../../config/setting";
import $ from "jquery";

class ServicesList extends React.Component {
    constructor(props) {
        super(props);
        this.servicesListShowClass = "ServicesList-list-contend-show";
        this.servicesListHiddenClass = "ServicesList-list-contend-hidden";

        this.selectedClass = "ServicesList-list-select-serviceType-selected";
        this.unselectedClass = "ServicesList-list-select-serviceType-unselected";

        this.selectedItemlClass = "ServicesList-list-select-selected-has";
        this.unselectedItemClass = "ServicesList-list-select-selected-un";

        this.showPopupClass = "ServicesList-popupShow";
        this.hiddenPopupClass = "ServicesList-popupHidden";
        this.servicesListTabs = [
            {title:"服务基本信息",values:
                    [
                    {name:"服务名称",key:"serviceTitle"},
                    {name:"服务地址",key:"serviceUrl"},
                    {name:"服务简介",key:"servicesIntroduction"},
                    {name:"关键字",key:"keywords"},
                    {name:"服务类型",key:"serviceType"},
                    {name:"主题分类",key:"serviceThemeType"},
                    {name:"覆盖区域",key:"coverageArea"},
                    {name:"发布时间",key:"releaseTime"},
                    {name:"更新周期",key:"updateCycle"},
                ]
            },{title:"图层基本信息",values:
                    [
                    {name:"图层名称",key:"layerName"},
                    {name:"图层简介",key:"layerIntroduction"},
                    // {name:"数据范围",key:"dataScope"},
                    {name:"坐标系统",key:"referenceFrame"},
                    {name:"投影类型",key:"projectionType"},
                ]
            },{title:"服务联系方式",values:
                    [
                    {name:"地址",key:"serviceAddress"},
                    {name:"联系人",key:"serviceContact"},
                    {name:"联系人电话",key:"serviceTelephone"},
                    {name:"邮箱",key:"serviceEmail"},
                ]
            },{title:"服务调用示例",values:
                    [
                    {name:"请求方式",key:"requestMethod"},
                    // {name:"请求参数说明",key:"requestParameter"},
                    // {name:"返回字段说明",key:"resultField"},
                    {name:"核心代码示例",key:"coreCode"},
                    {name:"调用效果图",key:"effectImg"},
                ]
            }];
        this.servicesListTabsIndex = 0;

        this.state = {
            servicesListClass: this.servicesListShowClass,
            servicesListTabsIndex:0,
            service_theme_type_name: "主题分类：",
            service_theme_type: [],

            coverage_area_name: "覆盖范围：",
            coverage_area: [],
            coverage_area_selected: "",

            release_mechanism_name: "",
            release_mechanism: [],
            release_mechanism_selected: "",

            times_name: "发布时间：",
            times: [],

            selectedVessel: this.unselectedVesselClass,

            resourceList: [],

            dataItem: "",
            popupClass: this.hiddenPopupClass,
            openResConfirm: false,
        };
        this.activeTypeIndex = 0;
        this.activeType = false;
        this.activeCoverageIndex = 0;
        this.activeCoverage = false;
        this.activeYearIndex = 0;
        this.activeYear = false;

        this.queryParam = {
            serviceThemeType: null,
            coverageArea: null,
            releaseTime: null,
            keywords:null
        }
    }
    componentDidMount() {
        //获取主体分类
        fetch(deploymentUrl + '/tdtyns/TdtServiceThemeType/list?delFlag=1&siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("主题分类查询失败");
                return;
            }
            this.setState({service_theme_type: data.rows});
        });
        //获取行政区划
        fetch(deploymentUrl + '/tdtyns/SysAdministrativeRegion/list?delFlag=1', {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("行政区划查询失败");
                return;
            }
            //过滤 市级和省
            let temps = [];
            for (let item of data.data) {
                if (item.regionRank === "0" || item.regionRank === "1") {
                    temps.push(item)
                }
            }
            this.setState({coverage_area: temps});
        });

        fetch(deploymentUrl + '/tdtyns/TdtDevService/getYearList?siteId=' + siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("年份查询失败");
                return;
            }
            if (data.data.length !== 0) {
                let rows = data.data.sort().reverse();
                this.setState({times: rows})
            }
        });
    }

    //选择类型
    serviceTypeSelectedClick(item,key, target) {
        if (this.activeTypeIndex === key) {
            if (this.activeType) {
                this.setState({["service_theme_type_color_" + key]: this.unselectedClass});
                this.queryParam.serviceThemeType = null;
                this.activeType = false;
                this.activeTypeIndex = key;
            }else {
                this.setState({["service_theme_type_color_" + key]: this.selectedClass});
                this.queryParam.serviceThemeType = item.id;
                this.activeType = true;
                this.activeTypeIndex = key;
            }
        }else {
            this.setState({["service_theme_type_color_" + this.activeTypeIndex]: this.unselectedClass});
            this.setState({["service_theme_type_color_" + key]: this.selectedClass});
            this.queryParam.serviceThemeType = item.id;
            this.activeType = true;
            this.activeTypeIndex = key;
        }
        this.queryParam.keywords = null;
        //根据条件查询
        this.getPageAmount();
    };

    //选择范围
    coverageAreaSelectedClick(item,key, target) {
        if (this.activeCoverageIndex === key) {
            if (this.activeCoverage) {
                this.setState({["coverage_area_color_" + key]: this.unselectedClass});
                this.queryParam.coverageArea = null;
                this.activeCoverage = false;
                this.activeCoverageIndex = key;
            }else {
                this.setState({["coverage_area_color_" + key]: this.selectedClass});
                this.queryParam.coverageArea = item.id;
                this.activeCoverage = true;
                this.activeCoverageIndex = key;
            }
        }else {
            this.setState({["coverage_area_color_" + this.activeCoverageIndex]: this.unselectedClass});
            this.setState({["coverage_area_color_" + key]: this.selectedClass});
            this.queryParam.coverageArea = item.id;
            this.activeCoverage = true;
            this.activeCoverageIndex = key;
        }
        this.queryParam.keywords = null;
        //根据条件查询
        this.getPageAmount();
    };
    //选择年份
    yearSelectedClick(item,key, target) {
        if (this.activeYearIndex === key) {
            if (this.activeYear) {
                this.setState({["times_color_" + key]: this.unselectedClass});
                this.queryParam.releaseTime = null;
                this.activeYear = false;
                this.activeYearIndex = key;
            }else {
                this.setState({["times_color_" + key]: this.selectedClass});
                this.queryParam.releaseTime = item + "-1-1";
                this.activeYear = true;
                this.activeYearIndex = key;
            }
        }else {
            this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
            this.setState({["times_color_" + key]: this.selectedClass});
            this.queryParam.releaseTime = item + "-1-1";
            this.activeYear = true;
            this.activeYearIndex = key;
        }
        this.queryParam.keywords = null;
        //根据条件查询
        this.getPageAmount();
    };
    //查询数据---分页
    pagingQuery(activePage, pageSize) {
        let url = deploymentUrl + '/tdtyns/TdtDevService/list?pageNum='+activePage+'&pageSize='+pageSize+'&delFlag=1&siteId=' + siteValue;
        if (this.queryParam.serviceThemeType !== null) {
            url = url + '&serviceThemeType=' + this.queryParam.serviceThemeType;
        }
        if (this.queryParam.coverageArea !== null) {
            url = url + '&coverageArea=' + this.queryParam.coverageArea
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keywords !== null) {
            url = url + '&keywords=' + this.queryParam.keywords
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            console.log("resourceList===",data.rows);
            this.setState({resourceList: data.rows});
            this.childSearch.startSearch(false)
        });
    }

    // 示例代码
    testExample(){
        return (
            <>
                <p>{"// OpenLayers 为例："}</p>
                <p>{"let map = new TileLayer({"}</p>
                <p style={{textIndent: "2em"}}>{'title:"{标题}",'}</p>
                <p style={{textIndent: "2em"}}>{'source:new XYZ({'}</p>
                <p style={{textIndent: "4em"}}>{'url: "https://{服务地址}/tile/default/{z}/{y}/{x}?key=用户的key",'}</p>
                <p style={{textIndent: "4em"}}>{'projection:"EPSG:{坐标系3857或4490}"'}</p>
                <p style={{textIndent: "2em"}}>{'})'}</p>
                <p>{'});'}</p>
            </>
        )
    }

    //跳转控制台，登录省节点账号
    confirmRes(e){
        this.setState({openResConfirm: false});
        this.exitLoadTianditu()
        window.open(controlUrl,"_blank")
    };


    //本省节点账号登录直接跳转控制台，否则弹出提示
    onToRes(e){
        let that = this;
        //获取国家用户信息---能获取到-国家用户登录；获取不到-未登录
        $.ajax({
            type: 'get',
            async: false,
            url: deploymentUrl + '/auth/tianditu/casInfo',
            success: function (res) {
                if (res.code === 200) {
                    that.setState({openResConfirm: true});
                } else {
                    that.setState({openResConfirm: false});
                    window.open(controlUrl,"_blank")
                }
            },
            error: function (err) {
                that.setState({openResConfirm: false});
                window.open(controlUrl,"_blank")

            }
        });
    };

    render() {
        return (
            <div className={"serviceList-container"}>
                <div className={'ServicesList-list'}>
                    <div className={'ServicesList-list-title'}>
                        {"开发资源 > 服务资源（天地图·云南）"}
                    </div>
                    <div className={this.state.servicesListClass}>
                        <div className={'ServicesList-list-select'}>
                            <div className={"ServicesList-list-select-serviceType-line"}>
                                <div className={"ServicesList-list-select-serviceType"}>
                                <span className={"ServicesList-list-select-serviceType-name"}>
                                    {this.state.service_theme_type_name}
                                </span>
                                </div>
                                <div className={"ServicesList-list-select-serviceType-con"}>
                                    {
                                        this.state.service_theme_type.map((item, key) =>
                                                <span onClick={this.serviceTypeSelectedClick.bind(this, item,key)}
                                                      className={this.state["service_theme_type_color_" + key]===undefined?this.unselectedClass:this.state["service_theme_type_color_" + key]} key={key}>
                                        {item.typeName}
                                    </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={"ServicesList-list-select-serviceType-line"}>
                                <div className={"ServicesList-list-select-serviceType"}>
                                <span className={"ServicesList-list-select-serviceType-name"}>
                                    {this.state.coverage_area_name}
                                </span>
                                </div>
                                <div className={"ServicesList-list-select-serviceType-con"}>
                                    {
                                        this.state.coverage_area.map((item, key) =>
                                                <span onClick={this.coverageAreaSelectedClick.bind(this, item,key)}
                                                      className={this.state["coverage_area_color_" + key] === undefined ? this.unselectedClass : this.state["coverage_area_color_" + key]}
                                                      key={key}>
                                        {item.regionAlias}
                                    </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={"ServicesList-list-select-serviceType-line"}>
                                <div className={"ServicesList-list-select-serviceType"}>
                                <span className={"ServicesList-list-select-serviceType-name"}>
                                    {this.state.times_name}
                                </span>
                                </div>
                                <div className={"ServicesList-list-select-serviceType-con"}>
                                    {
                                        this.state.times.map((item, key) =>
                                                <span onClick={this.yearSelectedClick.bind(this, item,key)}
                                                      className={this.state["times_color_" + key]===undefined?this.unselectedClass:this.state["times_color_" + key]} key={key}>
                                        {item + " 年"}
                                    </span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={'ServicesList-list-search'}>
                            <Search search={this.searchClick} onRef={(ref) => {this.childSearch = ref;}}/>
                        </div>
                        <div className={'ServicesList-source-apply'}>
                            <Popconfirm
                                open={this.state.openResConfirm}
                                title="当前账号为国家天地图节点账号，如需申请或查看本地资源请使用省级节点账号登录"
                                onCancel={() =>{ this.setState({openResConfirm: false});}}
                                onConfirm={this.confirmRes.bind(this)}
                                okText="确定"
                                cancelText="取消"
                            >
                            <a onClick={this.onToRes.bind(this)} >本地资源申请>></a>
                            </Popconfirm>
                        </div>
                        <div className={'ServicesList-list-content'}>
                            <div className={'ServicesList-list-content-list-items'}>
                                {
                                    this.state.resourceList.map((item, key) =>
                                        <div className={"ServicesList-list-content-list-item"} key={key}>
                                            <div className="ServicesList-list-content-list-item-title">
                                                <p className="title">{item.serviceTitle}</p>
                                                <span onClick={this.lookOver.bind(this, item)}
                                                      className="detail">查看&gt;&gt;</span>
                                            </div>
                                            <div className={"ServicesList-list-content-list-item-con"}>
                                                <div className={"ServicesList-list-content-list-item-con-logo"}>
                                                    <img onClick={this.lookOver.bind(this, item)} src={staticSourceUrl + item.titleImg}/>
                                                </div>
                                                <div className={"ServicesList-list-content-list-item-con-des"}>
                                                    <div className={"ServicesList-list-content-list-item-con-des_"}>
                                                        <span>发布时间：</span>
                                                        <p>
                                                            {
                                                                item.releaseTime !== null && item.releaseTime !== ""? item.releaseTime.substring(0,10):""
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className={"ServicesList-list-content-list-item-con-des_"}>
                                                        <span>摘要信息：</span>
                                                        <p>{item.serviceAbstract}</p>
                                                    </div>
                                                    <div className={"ServicesList-list-content-list-item-con-des_"}>
                                                        <span>关键字：</span>
                                                        <div className={"ServicesList-list-content-list-item-con-des_a"}>
                                                            {
                                                                item.keywords.split(/[,| ，]/g).map((itemT, index) =>
                                                                    itemT !=="" && itemT!==null?<span key={index}>{itemT}</span>:false
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={"ServicesList-list-content-list-item-con-des_"}>
                                                        <div className={"ServicesList-list-content-list-item-con-show-count"}>
                                                            <div title={"查看量"}
                                                                 className={"ServicesList-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe601;</i>
                                                                <span>{item.viewCount}</span>
                                                            </div>
                                                            <div title={"调用量"}
                                                                 className={"ServicesList-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe619;</i>
                                                                <span>{item.callCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div id={"ServicesList-list-content-pagingButton"}
                                 className={'ServicesList-list-content-pagingButton'}>
                                <PagingButton pagingQuery={this.pagingQuery.bind(this)}
                                              getPageAmount={this.getPageAmount.bind(this)} onRef={(ref) => {this.child = ref;}}/>
                            </div>
                        </div>
                    </div>
                    {/*弹窗*/}
                    <div className={this.state.popupClass}>
                        <div className={"ServicesList-popup-title"}>
                            {
                                this.servicesListTabs.map((item,index1)=>
                                    <div onClick={this.servicesListTabsClick.bind(this,index1,item.values)} className={this.state["ServicesList_tabs_" + index1] === undefined ? "ServicesList-popup-tabs-un" : this.state["ServicesList_tabs_" + index1]} key={index1}>{item.title}</div>
                                )
                            }
                            <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                        </div>
                        <div className={"ServicesList-popup-Vessel"}>
                            {
                                this.state.servicesListTabsIndex === 3?
                                    <div>
                                        <div className={"ServicesList-popup-Vessel-item_a"}>
                                            <span>{this.servicesListTabs[this.state.servicesListTabsIndex].values[0].name + "："}</span>
                                            {/*<p>{this.state.dataItem[this.servicesListTabs[this.state.servicesListTabsIndex].values[0].key]}</p>*/}
                                            <p>GET</p>
                                        </div>
                                        {/*<div className={"ServicesList-popup-Vessel-item_a"}>*/}
                                        {/*    <span>{this.servicesListTabs[this.state.servicesListTabsIndex].values[1].name + "："}</span>*/}
                                        {/*    <p>{this.state.dataItem[this.servicesListTabs[this.state.servicesListTabsIndex].values[1].key]}</p>*/}
                                        {/*</div>*/}
                                        {/*<div className={"ServicesList-popup-Vessel-item_a"}>*/}
                                        {/*    <span>{this.servicesListTabs[this.state.servicesListTabsIndex].values[2].name + "："}</span>*/}
                                        {/*    <p>{this.state.dataItem[this.servicesListTabs[this.state.servicesListTabsIndex].values[2].key]}</p>*/}
                                        {/*</div>*/}
                                        <div className={"ServicesList-popup-Vessel-item_a"}>
                                            <span>{this.servicesListTabs[this.state.servicesListTabsIndex].values[1].name + "："}</span>
                                            <div className={"ServicesList-popup-Vessel-item_a-code"}>{this.testExample()}</div>
                                            {/*<div className={"ServicesList-popup-Vessel-item_a-code"} dangerouslySetInnerHTML={{__html:this.state.dataItem[this.servicesListTabs[this.state.servicesListTabsIndex].values[1].key]}}/>*/}
                                        </div>
                                        <div className={"ServicesList-popup-Vessel-item_a"}>
                                            <span>{this.servicesListTabs[this.state.servicesListTabsIndex].values[2].name + "："}</span>
                                            <img src={staticSourceUrl + this.state.dataItem[this.servicesListTabs[this.state.servicesListTabsIndex].values[2].key]}/>
                                        </div>
                                    </div>:
                                    <div>
                                        {
                                            this.servicesListTabs[this.state.servicesListTabsIndex].values.map((value,keya)=>
                                                <div className={"ServicesList-popup-Vessel-item"} key={keya}>
                                                    <span>{value.name + "："}</span>
                                                    <p>
                                                        {
                                                            this.setItemValue(value)
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        );
    }
    //设置值---主题类型--覆盖范围
    setItemValue(value){
        let valueTemp = this.state.dataItem[value.key];
        if (value.key === "serviceUrl") {
            valueTemp = this.state.dataItem["metadataAddress"];
            if (!defined(valueTemp)){
                valueTemp = this.state.dataItem[value.key];
            }
        }
        if (value.key === "serviceThemeType") {
            for (let type of this.state.service_theme_type) {
                if (type.id.toString() === valueTemp) {
                    valueTemp = type.typeName;
                }
            }
        }
        if (value.key === "coverageArea") {
            for (let type of this.state.coverage_area) {
                if (type.id.toString() === valueTemp) {
                    valueTemp = type.regionAlias;
                }
            }
        }
        return valueTemp;
    }
    //选项卡  事件
    servicesListTabsClick(index,target,values){
        this.setState({["ServicesList_tabs_" + this.servicesListTabsIndex]: "ServicesList-popup-tabs-un"});
        this.servicesListTabsIndex = index;
        this.setState({servicesListTabsIndex: index});
        this.setState({["ServicesList_tabs_" + index]: "ServicesList-popup-tabs-has"});
    }
    //取消所有选择
    cancelSelectAll(){
        this.setState({["service_theme_type_color_" + this.activeTypeIndex]: this.unselectedClass});
        this.queryParam.serviceThemeType = null;
        this.activeType = false;
        this.activeTypeIndex = 0;

        this.setState({["coverage_area_color_" + this.activeCoverageIndex]: this.unselectedClass});
        this.queryParam.coverageArea = null;
        this.activeCoverage = false;
        this.activeCoverageIndex = 0;

        this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
        this.queryParam.releaseTime = null;
        this.activeYear = false;
        this.activeYearIndex = 0;

        this.queryParam.keywords = null;
    }

    // 搜索-------功能---函数---分页===================
    searchClick = (searchIn) => {
        this.cancelSelectAll();
        this.queryParam.keywords = searchIn;
        this.getPageAmount();
    };

    // 查询总页码---加载查询列表--test
    getPageAmount() {
        let url = deploymentUrl + '/tdtyns/TdtDevService/getPageTotal?delFlag=1&siteId=' + siteValue;
        if (this.queryParam.serviceThemeType !== null) {
            url = url + '&serviceThemeType=' + this.queryParam.serviceThemeType;
        }
        if (this.queryParam.coverageArea !== null) {
            url = url + '&coverageArea=' + this.queryParam.coverageArea
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keywords !== null) {
            url = url + '&keywords=' + this.queryParam.keywords
        }

        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            let amountPage = Math.floor((data.data-1) / 10) + 1;
            this.child.setAmountPage(amountPage);
        });
    }

    //查看按钮
    lookOver(item, target) {
        this.setState({popupClass: this.showPopupClass});
        this.setState({servicesListClass: this.servicesListHiddenClass});
        this.setState({dataItem: item});
        this.servicesListTabsClick(0, undefined);

        let url = deploymentUrl + '/tdtyns/TdtDevService/getViewCount?id=' + item.id;
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查看次数统计失败");
                return;
            }
            console.log("查看次数统计成功");
        });
    }
    //关闭弹窗按钮
    closePopup(target){
        this.setState({popupClass: this.hiddenPopupClass});
        this.setState({servicesListClass: this.servicesListShowClass});
        this.setState({dataItem: ""});
    }
}

export default ServicesList
