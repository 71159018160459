import Modal from "antd/es/modal";
const buttonProps = {
    shape: "round",
    size: "small",
    styles: {},
    type: "primary"
}

const config = {
    title: '对话框',
    content: "内容",
    autoFocusButton: null,
    cancelButtonProps: buttonProps,
    cancelText: "取消",
    centered: true,
    closable: false,
    keyboard: false,
    mask: true,
    maskClosable: false,
    okButtonProps: buttonProps,
    okText: "确定",
    onCancel: ()=>{},
    onOk: ()=>{}
};
/**
 * 提示信息 -- 对话框
 * @param title  标题
 * @param content 内容 ReactNode
 * @param closable 是否显示右上角的关闭按钮
 * @param okText  确认按钮文字
 * @param onOk 点击确定回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 */
const infoDialog = (title,content,closable,okText,onOk) =>{
    config.title = title;
    config.content = content;
    config.closable = closable;
    config.okText = okText;
    config.onOk = onOk;
    Modal.info(config)
}

/**
 * 成功提示 -- 对话框
 * @param title  标题
 * @param content 内容 ReactNode
 * @param closable 是否显示右上角的关闭按钮
 * @param okText  确认按钮文字
 * @param onOk 点击确定回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 * @constructor
 */
const successDialog = (title,content,closable,okText,onOk) =>{
    config.title = title;
    config.content = content;
    config.closable = closable;
    config.okText = okText;
    config.onOk = onOk;
    Modal.success(config)
}
/**
 * 错误提示 -- 对话框
 * @param title  标题
 * @param content 内容 ReactNode
 * @param closable 是否显示右上角的关闭按钮
 * @param okText  确认按钮文字
 * @param onOk 点击确定回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 * @constructor
 */
const errorDialog = (title,content,closable,okText,onOk) =>{
    config.title = title;
    config.content = content;
    config.closable = closable;
    config.okText = okText;
    config.onOk = onOk;
    Modal.error(config)
}
/**
 * 警告提示 -- 对话框
 * @param title  标题
 * @param content 内容 ReactNode
 * @param closable 是否显示右上角的关闭按钮
 * @param okText  确认按钮文字
 * @param onOk 点击确定回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 * @constructor
 */
const warningDialog = (title,content,closable,okText,onOk) =>{
    config.title = title;
    config.content = content;
    config.closable = closable;
    config.okText = okText;
    config.onOk = onOk;
    Modal.warning(config)
}
/**
 * 确认提示 -- 对话框
 * @param title  标题
 * @param content 内容 ReactNode
 * @param closable 是否显示右上角的关闭按钮
 * @param okText  确认按钮文字
 * @param onOk 点击确定回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 * @param cancelText 取消按钮文字
 * @param onCancel 取消回调，参数为关闭函数，返回 promise 时 resolve 后自动关闭
 * @constructor
 */
const confirmDialog = (title,content,closable,okText,onOk,cancelText,onCancel) =>{
    config.title = title;
    config.content = content;
    config.closable = closable;
    config.okText = okText;
    config.onOk = onOk;
    config.cancelText = cancelText;
    config.onCancel = onCancel;
    Modal.confirm(config)
}
export {
    infoDialog,
    successDialog,
    errorDialog,
    warningDialog,
    confirmDialog
}