import React from "react";
import Footer from "../../../footer/Footer";
import './SuggestOffer.css';
import {
    Button,
    Checkbox,
    Form,
    Input,
    Radio,
    Space,
    Upload,
    message,
    Result,
    Modal,
    Tooltip,
    Divider,
    Spin
} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import {LoadingOutlined} from "@ant-design/icons";
import { getYNToken, getZWToken} from "../../../../modules/CookieTools";
import {Store} from "../../../../store/Store";
import defined from "../../../../modules/defined";
import {deploymentUrl, siteValue} from "../../../../config/setting";

const {TextArea} = Input;
const loadingIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

class SuggestOffer extends React.Component {
    // 表单引用
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            // 意见反馈表单内容
            gbookData: {
                creationName: null,
                creationContent: null,
                creationEmail: null,
                creationImg: null,
                creationPhone: null,
                creationTitle: null,
                creationType: null,
                siteId: siteValue,
                state: '0',
            },
            // 提交按钮状态
            submitDisabled: true,
            checkedState: false,
            // 上传图片列表
            imageList: [],
            // 弹窗显示
            isModalVisible: false,
            // 加载动画显示状态
            loadingState: false,
            // 登录状态
            loginState: false,
            userName: null
        }

    }

    componentDidMount() {
        // 登录状态
        let userName = Store.getState().user.userName
        if (defined(userName) && defined(getYNToken())){ //天地图登录
            this.setState({loginState: true});
            this.setState({userName: userName});
        }else if (defined(userName) && defined(getZWToken())){ //政务网登录
            this.setState({loginState: true});
            this.setState({userName: userName});
        }else { //没有登录
            this.setState({loginState: false});
        }
    }

    render() {
        return (
            <div className={"suggestOffer-all"}>
                <p className={"suggestOffer-tip"}>
                    {
                        this.state.loginState?"温馨提示： 系统故障、咨询服务、投诉，请电话联系0871-64166028，我们将竭诚为您解决，感谢您的关注和反馈":"温馨提示： 请先您登录,再提交建议，感谢您的关注与支持"
                    }
                </p>
                <Spin size="large"
                      tip="Loading..."
                      indicator={loadingIcon}
                      spinning={this.state.loadingState}>
                    <div className={"suggestOffer-form"}>
                        <Form
                            ref={this.formRef}
                            name="suggestOffer"
                            disabled={!this.state.loginState}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off">
                            <Form.Item
                                className={"suggestOffer-form-item"}
                                label="标题"
                                name="creationTitle"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入标题!',
                                    },
                                ]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                className={"suggestOffer-form-item-con"}
                                name="creationType"
                                label="类型"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择类型!',
                                    },
                                ]}>
                                <Radio.Group name={"creationType"}>
                                    <Space size={10}>
                                        <Radio.Button value="地图服务"> 地图服务 </Radio.Button>
                                        <Radio.Button value="开发资源"> 开发资源 </Radio.Button>
                                        <Radio.Button value="专题频道"> 专题频道 </Radio.Button>
                                        <Radio.Button value="应用案例"> 应用案例 </Radio.Button>
                                        <Radio.Button value="地图产品"> 地图产品 </Radio.Button>
                                        <Radio.Button value="资源下载"> 资源下载 </Radio.Button>
                                        <Radio.Button value="其他"> 其他 </Radio.Button>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                className={"suggestOffer-form-item-con"}
                                label="内容"
                                name="creationContent"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入内容!',
                                    },
                                ]}>
                                <TextArea rows={8}/>
                            </Form.Item>
                            <Form.Item
                                className={"suggestOffer-form-item"}
                                label="电话"
                                name="creationPhone"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^(((1[3-9]{1}[0-9]{1}))+\d{8})$/,
                                        message: '电话格式不正确!',
                                    }
                                ]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                className={"suggestOffer-form-item"}
                                label="邮箱"
                                name="creationEmail"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: '邮箱格式不正确!',
                                    }
                                ]}>
                                <Input/>
                            </Form.Item>
                            <Space size={5}>
                                <Tooltip placement="left"
                                         title={"仅允许上传2张JPG/PNG格式图片"}
                                         overlayInnerStyle={{fontSize: 10}}>
                                    <QuestionCircleOutlined style={{color:"red", cursor: "pointer",fontSize: '13px',paddingRight:"2px", marginBottom: '105px'}}/>
                                </Tooltip>
                                <Form.Item
                                    label="图片上传"
                                    valuePropName="fileList">
                                    <Upload
                                        action={deploymentUrl + "/tdtyns/TdtGbookNew/image"}
                                        listType="picture-card"
                                        fileList={this.state.imageList}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handleChange}
                                        onRemove={this.handleRemove}>
                                        {this.state.imageList.length >= 2 ? null : (
                                            <div>
                                                <PlusOutlined/>
                                                <div style={{marginTop: 8}}>
                                                    上传
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Space>
                            <div className={"suggest-check"}>
                                <Checkbox checked={this.state.checkedState}
                                          onChange={this.suggestCheckBoxChange}>已知晓建议内容将会被公开，且未提交敏感信息。</Checkbox>
                            </div>
                            <div className={"suggest-submit"}>
                                <Button type="primary" htmlType="submit" disabled={this.state.submitDisabled}>
                                    提交建议
                                </Button>
                            </div>
                            <Modal title="留言反馈结果"
                                   visible={this.state.isModalVisible}
                                   closable={false}
                                   footer={null}>
                                <Result
                                    status="success"
                                    title="留言成功!"
                                    subTitle="管理员审核完成后可在留言列表中查看，同时将在1~2个工作日内给您回复"
                                    extra={[
                                        <Button
                                            type="primary"
                                            key="console"
                                            target="_self"
                                            href="/SuggestFeedback"
                                            style={{width: 56, height: 26}}
                                        >
                                            回到首页
                                        </Button>,
                                        <Button key="buy"
                                                onClick={() => this.setState({"isModalVisible": false})}>
                                            继续留言
                                        </Button>,
                                    ]}
                                />
                            </Modal>
                        </Form>
                    </div>
                </Spin>
            </div>
        )
    }

    // 表单提交
    onFinish = (values) => {
        // 请求前加载动画
        this.setState({loadingState: true});
        let TdtGbookEntity = null;
        for (let key in values) {
            TdtGbookEntity = Object.assign(this.state.gbookData, {[key]: values[key]});
        }
        // 单独存入图片url
        TdtGbookEntity.creationImg = this.state.creationImg;
        TdtGbookEntity.creationName = this.state.userName;
        TdtGbookEntity.deviceType = "PC端";
        console.log("TdtGbookEntity",TdtGbookEntity)
        fetch(deploymentUrl + '/tdtyns/TdtGbookNew/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(TdtGbookEntity)
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("留言失败");
                this.setState({
                    // 关闭加载动画
                    loadingState: false
                });
            } else {
                // 清空表单
                this.formRef.current.resetFields();
                this.setState({
                    // 清空图片上传列表
                    imageList: [],
                    // 恢复提交按钮默认状态
                    submitDisabled: true,
                    checkedState: false,
                    // 留言成功显示弹窗
                    isModalVisible: true,
                    // 请求结束关闭加载动画
                    loadingState: false
                });
            }
        });
    };

    // 表单提交失败
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // 提示按钮勾选状态改变
    suggestCheckBoxChange = (e) => {
        this.setState({
            submitDisabled: !e.target.checked,
            checkedState: e.target.checked
        });
    };

    // 图片上传校验
    beforeUpload = (file) => {
        // 文件类型
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('图片上传类型为JPG/PNG!');
        }
        // 文件大小
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片不得大于2MB!');
        }
        // 清空列表
        return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
    };

    // 图片上传后状态改变钩子函数
    handleChange = (info) => {
        try {
            this.setState({imageList: info.fileList});
            // 上传单张图片时fileList为空
            if (info.fileList.length !== 0) {
                if (info.fileList[info.fileList.length - 1].status === "done") {
                    let file = info.fileList[info.fileList.length - 1];
                    //服务端返回数据url
                    let url;
                    if (this.state.creationImg === null || this.state.creationImg === undefined) {
                        url = "" + file.response.data;
                    } else {
                        url = this.state.creationImg + "," + file.response.data;
                    }
                    this.setState({creationImg: url});
                }
            } else {
                if (info.file.status === "done") {
                    //服务端返回数据url
                    let url = "" + info.file.response.data;
                    this.setState({creationImg: url});
                }
            }
        } catch (e) {
            alert("图片状态有误！" + e);
        }
    };

    // 图片移除时的钩子函数
    handleRemove = (file) => {
        if (file !== undefined) {
            let fileName = file.response.data[0];
            // 图片删除
            fetch(deploymentUrl + '/tdtyns/TdtGbookNew/deleteFile?filePath=' + fileName, {
                method: 'GET'
            }).then(res => res.json()).then(data => {
                if (data.code !== 200) {
                    alert("图片删除失败");
                }
            });
        }
    }

}

export default SuggestOffer
